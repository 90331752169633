import { Paths } from 'routes/paths';
import { AppState } from 'redux/store';
import { UsersIcon } from 'icons/users';
import { OrdersIcon } from 'icons/orders';
import { useSelector } from 'react-redux';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { TabLeftIcon } from 'icons/tab-left';
import { SettingsIcon } from 'icons/settings';
import { useNavigate } from 'react-router-dom';
import { StatisticIcon } from 'icons/statistic';
import { Products3Icon } from 'icons/products-3';
import { DepartmentsIcon } from 'icons/departments';
import { LogoWithoutTitleIcon } from 'icons/logo-without-title';
import { Selectors as AuthSelectors } from 'redux/auth.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflowsIcon } from 'icons/production-workflows';
import { useIsPermitted } from 'modules/permission-guard/hooks/use-current-user-permissions';
import Tenants from 'modules/authorized-layout/components/sidebar-menu/component/tenants/tenants';
import HorizontalShiftAnimation from 'components/wrapper-with-shift-animation/horisontal-shift-animation';
import { NavigationLink } from 'modules/authorized-layout/components/sidebar-menu/component/navigation-link/navigation-link';
import s from './sidebar-menu.module.scss';

type Props = {
  isMobileMenuOpened: boolean;
  setIsMobileMenuOpened: Dispatch<SetStateAction<boolean>>;
};

export const SidebarMenu = ({ isMobileMenuOpened, setIsMobileMenuOpened }: Props) => {
  const [isExpanded, setIsExpanded] = useState(!!JSON.parse(localStorage.getItem('isMenuOpen')));
  const navigate = useNavigate();
  const isUserActive = useSelector((state: AppState) => AuthSelectors.isUserActiveInTenant(state));

  const isTextExpanded = isExpanded || (!isExpanded && isMobileMenuOpened);

  const toggleSidebarExpandability = () => setIsExpanded((prev) => !prev);
  const closeMobileMenu = () => setIsMobileMenuOpened(false);

  useEffect(() => {
    window.localStorage.setItem('isMenuOpen', JSON.stringify(isExpanded));
    window.dispatchEvent(new Event('storage'));
  }, [isExpanded]);

  const isUsersPagePermitted = useIsPermitted(Permission.webUsersViewEdit, [AccessLevel.access]);
  const isAnalyticsPagePermitted = useIsPermitted(Permission.webAnalyticsView, [AccessLevel.access]);
  const isProductionPagePermitted = useIsPermitted(Permission.webProductionView, [AccessLevel.access]);
  const isSettingsPagePermitted = useIsPermitted(Permission.webSettingsViewEdit, [AccessLevel.access]);
  const isDepartmentsPagePermitted = useIsPermitted(Permission.webDepartmentsViewEdit, [AccessLevel.access]);

  return (
    <div data-is-expanded={isExpanded} data-is-mobile-menu-opened={isMobileMenuOpened} className={s.menu_container}>
      <div className={isExpanded ? s.menu_wrapper : `${s.menu_wrapper} ${s.menu_wrapper_collapsed}`}>
        <header className={s.header}>
          <div className={s.animated_logo} onClick={() => navigate('/')}>
            <LogoWithoutTitleIcon className={s.logo_icon} height={30} width={30} />
            <HorizontalShiftAnimation isVisible={isExpanded} maxWidthWhenIsShown={68} animationTime={0.3}>
              <p className={s.logo_text}>hesh</p>
            </HorizontalShiftAnimation>
          </div>
          <Tenants isOpen={isTextExpanded} />
        </header>
        <div className={s.body}>
          <div className={s.item_list}>
            {isUserActive && (
              <>
                {isAnalyticsPagePermitted && (
                  <>
                    <div
                      className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                      onClick={closeMobileMenu}
                    >
                      <NavigationLink path={Paths.Home} name="Analytics" icon={StatisticIcon} isExpandedView={isTextExpanded} />
                    </div>
                    <div className={s.dash} />
                  </>
                )}

                <div
                  className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                  onClick={closeMobileMenu}
                >
                  <NavigationLink
                    icon={OrdersIcon}
                    name="Product search"
                    isExpandedView={isTextExpanded}
                    path={Paths.ProductSearch}
                  />
                  <NavigationLink
                    name="Products"
                    icon={Products3Icon}
                    isExpandedView={isTextExpanded}
                    path={`${Paths.Products}/all-products`}
                  />
                  {isProductionPagePermitted && (
                    <NavigationLink
                      name="Production"
                      path={Paths.Production}
                      isExpandedView={isTextExpanded}
                      icon={ProductionWorkflowsIcon}
                    />
                  )}
                </div>

                <div className={s.dash} />

                <div
                  className={isExpanded ? s.item_group : `${s.item_group} ${s.item_group_collapsed}`}
                  onClick={closeMobileMenu}
                >
                  {isUsersPagePermitted && (
                    <NavigationLink name="Users" icon={UsersIcon} path={`${Paths.Users}`} isExpandedView={isTextExpanded} />
                  )}
                  {isDepartmentsPagePermitted && (
                    <NavigationLink
                      name="Departments"
                      icon={DepartmentsIcon}
                      isExpandedView={isTextExpanded}
                      path={`${Paths.Departments}`}
                    />
                  )}
                </div>

                <div className={s.dash} />

                <div onClick={closeMobileMenu}>
                  {isSettingsPagePermitted && (
                    <NavigationLink
                      name="Settings"
                      icon={SettingsIcon}
                      isExpandedView={isTextExpanded}
                      path={`${Paths.Settings}`}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div onClick={toggleSidebarExpandability} className={s.switch_wrapper}>
          <div className={s.switch}>
            <div className={s.switch_icon_wrapper}>
              <TabLeftIcon className={s.switch_icon} data-isopen={isExpanded} />
            </div>
            <HorizontalShiftAnimation isVisible={isExpanded} maxWidthWhenIsShown={33}>
              <span className={s.arrow_text}>Hide</span>
            </HorizontalShiftAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};
