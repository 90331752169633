import { ReactNode, useMemo, useState } from 'react';
import { CircularProgress, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import Button from 'components/button/button';
import { EyeIcon } from 'icons/eye';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import { InactivatedIcon } from 'icons/inactivated';
import Label from '../label/label';
import s from './modal-item.module.scss';
import Autocomplete from '../ui-new/autocomplete/autocomplete';
import HighlightText from '../highlight-text/highlight-text';
import CustomChip from '../custom-chip/custom-chip';

type Props = {
  options: any[];
  label: string;
  placeholder: string;
  value: {
    id: string;
    name: string;
    [key: string]: any;
  } | null;
  newText?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  labelStyle?: string;
  isWarning?: boolean;
  withAvatar?: boolean;
  withNewOption?: boolean;
  optionClassName?: string;
  startAdornment?: ReactNode;
  isActiveTooltipTitle?: string;
  onAutocompleteChange: (event, newValue: any) => void;
  onBlur?: () => void;
  onTextFieldChange?: (event) => void;
  onViewIconClick?: (id: string) => void;
};

const ModalItem = ({
  label,
  value,
  options,
  newText,
  withAvatar,
  labelStyle,
  placeholder,
  withNewOption,
  startAdornment,
  className = '',
  optionClassName,
  loading = false,
  disabled = false,
  isActiveTooltipTitle = 'Product is inactive. To enable production for this product, you need to activate it.',
  onBlur,
  onViewIconClick,
  onTextFieldChange,
  onAutocompleteChange,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClose = () => setIsOpen(false);
  const handleOnOpen = () => setIsOpen(true);

  const filteredOptions = useMemo(() => {
    if (value) {
      return options.filter((item) => item.id !== value?.id);
    }
    return options;
  }, [options, value]);

  return (
    <div>
      <Label labelStyle={labelStyle}>{label}</Label>
      <Autocomplete
        fullWidth
        size="small"
        open={isOpen}
        value={value}
        disableClearable
        multiple={false}
        loading={loading}
        disabled={disabled}
        className={className}
        placeholder="Placeholder"
        options={filteredOptions}
        classes={{
          option: optionClassName,
          popper: s.popper,
        }}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, item) => option.id === item.id}
        noOptionsText={
          withNewOption ? (
            <div className={s.new_option_container} onClick={handleOnClose}>
              {value?.name} {newText || '(new)'}
            </div>
          ) : (
            'No options'
          )
        }
        onBlur={onBlur}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        onChange={onAutocompleteChange}
        renderInput={(params) => (
          <TextField
            size="medium"
            placeholder={placeholder}
            onChange={onTextFieldChange}
            {...params}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {params.InputProps.endAdornment}
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {!!value && !value?.is_active && Object.keys(value).includes('is_active') && (
                    <CustomChip text="Inactive" tooltipTitle={isActiveTooltipTitle} icon={<InactivatedIcon />} type="red" />
                  )}
                </InputAdornment>
              ),
              startAdornment: startAdornment ? (
                <InputAdornment className={s.start_icon} position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
            }}
            ref={params.InputProps.ref}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          let maxWidth;
          if (option.is_active && !!onViewIconClick) {
            maxWidth = '375px';
          } else if (!option.is_active && !!onViewIconClick) {
            maxWidth = '275px';
          } else {
            maxWidth = '410px';
          }
          return (
            <li style={{ padding: '6px 10px' }} {...props} key={option.id}>
              <div className={s.item_container}>
                <div className={s.icon_with_chip}>
                  <div className={option.icon ? s.icon_with_title : `${s.icon_with_title} ${s.text}`}>
                    {withAvatar && (
                      <AvatarCircle
                        isEmpty={false}
                        className={s.avatar}
                        image={option.avatar || ''}
                        lastName={option.lastName || ''}
                        firstName={option.firstName || ''}
                      />
                    )}
                    <div className={option.icon ? s.icon : ''}>{option.icon ? option.icon : ''}</div>
                    <HighlightText text={option?.name} query={inputValue} maxWidth={maxWidth} />
                  </div>
                  <div className={s.chip_with_button}>
                    {!option.is_active && Object.keys(option).includes('is_active') && (
                      <CustomChip
                        className={s.chip}
                        text="Inactive"
                        tooltipTitle={isActiveTooltipTitle}
                        icon={<InactivatedIcon />}
                        type="red"
                      />
                    )}
                    {!!onViewIconClick && (
                      <Button
                        className={s.button}
                        size="XS"
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          onViewIconClick(option.id);
                        }}
                      >
                        <EyeIcon width="16px" height="16px" />
                      </Button>
                    )}
                  </div>
                </div>
                {option.breadcrumbs ? (
                  <TextEllipsis maxWidth="400px">
                    <span className={`${s.title} ${s.breadcrumbs}`}>{option.breadcrumbs}</span>
                  </TextEllipsis>
                ) : (
                  ''
                )}
              </div>
            </li>
          );
        }}
      />
    </div>
  );
};

export default ModalItem;
