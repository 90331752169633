import { ENV, EnvCookies, Envs } from 'env-cookies';
import { KJUR } from 'jsrsasign';
import store from 'redux/store';

const SHARED_CHAT_SECRET = 'MiwxOTgsMTMwLDM0LDI1MCwxNzUsMjEyLDU3LDc4LDIzMSwyMzAsNjQsMzQsMjIyLDE0MCw4MA==';

export const chatWidget: any = document.querySelector('chat-widget');

export const useChatWidget = () => {
  function initChat() {
    setTimeout(() => {
      const { user, userTenants } = store.getState().auth;
      if (!user) return initChat();

      // For dev purposes
      // console.log(Object.getPrototypeOf(chatWidget));
      if (EnvCookies.get('auth')) {
        const { email, first_name, last_name } = user;
        const userCompanyName = userTenants?.find((item) => item.id === EnvCookies.get('tenant')).name;
        const companyNamePrefix = `${userCompanyName || 'Unknown'} | `;
        const envPrefixes = {
          development: '[dev] | ',
          local: '[local] | ',
          stage: '[stage] | ',
          production: '',
        } as { [key in Envs]: string };

        const header = {
          alg: 'HS256',
          typ: 'JWT',
        };
        const payload = {
          email,
          displayName: `${envPrefixes[ENV]}${companyNamePrefix}${first_name} ${last_name}`,
          iat: Date.now() / 1000,
          exp: Date.now() / 1000 + 60 * 5,
        };
        const token = KJUR.jws.JWS.sign('HS256', JSON.stringify(header), JSON.stringify(payload), SHARED_CHAT_SECRET);

        chatWidget.loginWithToken(token);
        chatWidget.show();

        const style = document.createElement('style');
        style.innerHTML = `
          .chat-widget-wrapper { 
            transition: bottom 0.6s, right 0.6s; 
          }
          .icons {
            transition: background-size .1s, width 0.6s, height 0.6s !important;
          }
          .chat-icon-container {
            transition: width 0.6s, height 0.6s;
          }
          .chat-icon-button { 
            transition: width 0.6s, height 0.6s !important;
          }
        `;
        chatWidget.shadowRoot.appendChild(style);
      }
      return undefined;
    }, 1000);
  }

  chatWidget.onload = () => {
    chatWidget.hide();
    initChat();
  };

  const hide = () => {
    const interval = setInterval(() => {
      if (chatWidget) {
        chatWidget.style.display = 'none';
        clearInterval(interval);
      }
    }, 100);
  };

  const show = () => {
    const interval = setInterval(() => {
      if (chatWidget) {
        chatWidget.style.display = '';
        clearInterval(interval);
      }
    }, 100);
  };

  const setPosition = ({ bottom, right }) => {
    let widget = document.querySelector('chat-widget');
    let attempts = 0;

    while (!widget && attempts < 20) {
      widget = document.querySelector('chat-widget');
      attempts += 1;
    }
    const style = document.createElement('style');
    style.innerHTML = `
      .chat-widget-wrapper { 
        bottom: ${bottom} !important;
        right: ${right} !important; 
      }
    `;
    widget.shadowRoot.appendChild(style);
  };

  const getSmaller = () => {
    let widget = document.querySelector('chat-widget');
    let attempts = 0;

    while (!widget && attempts < 20) {
      widget = document.querySelector('chat-widget');
      attempts += 1;
    }
    const style = document.createElement('style');
    style.innerHTML = `
      .chat-icon-container {
        width: 40px !important;
        height: 40px !important;
      }
      .icons {
        width: 40px !important;
        height: 40px !important;
      }
      .chat-icon-button { 
        width: 40px !important;
        height: 40px !important;
      }
      .chat-icon {
        --regular-size: 22px !important;
        --big-size: 26px !important;
      }
    `;
    widget.shadowRoot.appendChild(style);
  };

  const setDefaultStyle = () => {
    let widget = document.querySelector('chat-widget');
    let attempts = 0;

    while (!widget && attempts < 20) {
      widget = document.querySelector('chat-widget');
      attempts += 1;
    }
    const style = document.createElement('style');
    style.innerHTML = `
      .chat-widget-wrapper { 
        bottom: 30px !important; 
        right: 30px !important; 
      }
      .chat-icon-container {
        width: 60px !important;
        height: 60px !important;
      }
      .icons {
        width: 60px !important;
        height: 60px !important;
      }
      .chat-icon-button { 
        width: 60px !important;
        height: 60px !important;
      }
      .chat-icon {
        --regular-size: 27px !important;
        --big-size: 30px !important;
      }
    `;
    widget.shadowRoot.appendChild(style);
  };

  return {
    hide: () => hide(),
    show: () => show(),
    getSmaller: () => getSmaller(),
    setDefaultStyle: () => setDefaultStyle(),
    setPosition: ({ bottom, right }) => setPosition({ bottom, right }),
  };
};
