import {
  ProductForProduction,
  ProductForProductionVersion,
  ProductForProductionVariant,
  ProductForProductionConfiguration,
} from 'services/products.model';
import { getDefaultVariantValue } from 'pages/production/controllers/helpers';
import { ProductForLaunch, ProductionWorkflow, ProductionWorkflowType } from 'services/production-workflow.model';
import { GroupedProductions, OrderDetails } from 'pages/production/controllers/products-launch-modal-controller/types';
import { ProductionStatusEnum } from 'types/status-enums';

export const groupProductionsForMultiLaunch = (selectedProductions: ProductionWorkflow[]) => {
  const grouped: Record<string, GroupedProductions> = {};

  selectedProductions.forEach((selectedProduction) => {
    const key = selectedProduction.variant.id;

    if (!grouped[key]) {
      grouped[key] = {
        orderDetails: [],
        configuredProductions: [],
        id: selectedProduction.id,
        data: [selectedProduction],
        version: `v.${selectedProduction.version}`,
        product_name: selectedProduction.product_name,
        configuration: selectedProduction.configuration.name,
        name: `${selectedProduction.product_name} ${selectedProduction.variant.name}`,
        variant: { id: selectedProduction.variant.id, name: selectedProduction.variant.name },
      };

      return;
    }

    grouped[key].data.push(selectedProduction);
  });

  const groupedAndSortedProductions = Object.values(grouped).sort(
    (a, b) => a.product_name.localeCompare(b.product_name) || a.variant.name.localeCompare(b.variant.name),
  );

  const productionsWithOrderDetails = groupedAndSortedProductions.map((group) => {
    const groupedOrders: Record<string, OrderDetails> = {};

    group.data.forEach((production) => {
      const { order } = production;
      const key = order.id;

      if (!groupedOrders[key]) {
        groupedOrders[key] = {
          count: 1,
          id: order.id,
          order_key: order.order_key,
          client_name: order.client_name,
          external_order_number: order.external_order_number,
          marketplace_order_number: order.marketplace_order_number,
        };

        return;
      }

      groupedOrders[key].count += 1;
    });

    return { ...group, orderDetails: Object.values(groupedOrders) };
  });

  return productionsWithOrderDetails;
};

export const checkIfProductReadyToLaunch = (product: ProductForLaunch) => {
  return (
    product.status === ProductionStatusEnum.To_Do &&
    product.version.is_active &&
    product.configuration?.is_active &&
    product.workflowTemplate?.is_active &&
    product.variant?.is_active
  );
};

export const checkIfNotAllElementsConfigured = (products: ProductForLaunch[]) => {
  const isInactiveEntity = products.some(
    (item) =>
      item.status === ProductionStatusEnum.To_Do &&
      (!item.is_active ||
        !item.version.is_active ||
        !item.configuration?.is_active ||
        !item.variant?.is_active ||
        (item.workflowType === ProductionWorkflowType.workflowTemplate && !item.workflowTemplate?.is_active)),
  );

  const isReadyToLaunch = products.some(
    (item) =>
      item.status === ProductionStatusEnum.To_Do &&
      ((item.workflowType === ProductionWorkflowType.workflowTemplate && item.workflowTemplate && !isInactiveEntity) ||
        item.workflowType === ProductionWorkflowType.warehouse) &&
      !isInactiveEntity,
  );

  const isContainsItself = products.some((item) => item.isContainsItself);

  return !isReadyToLaunch || isContainsItself;
};

export const getProductionParametersByVariant = (product: ProductForProduction, productVariantId: string) => {
  const chosenVersion: ProductForProductionVersion =
    product.versions.find((v) =>
      v.configurations.find((configuration) => configuration.variants.some((variant) => variant.id === productVariantId)),
    ) || null;
  const chosenConfiguration: ProductForProductionConfiguration =
    chosenVersion?.configurations.find((c) => c.variants.some((variant) => variant.id === productVariantId)) || null;
  const chosenVariant: ProductForProductionVariant = chosenConfiguration?.variants.find((v) => v.id === productVariantId) || null;

  return { chosenVersion, chosenVariant, chosenConfiguration };
};

export const getDefaultProductionParameters = (
  product: ProductForProduction,
  configurationName?: string,
  variantName?: string,
) => {
  const chosenVersion = product.versions.filter((v) => v.is_active)[0];
  const chosenConfiguration =
    chosenVersion.configurations.find((i) => i.name === configurationName) || chosenVersion.configurations[0];
  const chosenVariant =
    getDefaultVariantValue(variantName, chosenConfiguration.variants, true) || chosenConfiguration.variants[0];

  return { chosenVersion, chosenVariant, chosenConfiguration };
};
