import { AppState, GetStateFunction } from 'redux/store';
import { TaskTag } from 'services/task-tags.model';
import { TaskTagsService } from 'services/task-tags.service';
import {
  TaskTemplateBonus,
  UpdateWorkflowTaskTemplate,
  WorkflowTaskTemplate,
  WorkflowTaskTemplateTagRelation,
} from 'services/workflow-task-template.model';
import { v4 as uuidv4 } from 'uuid';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { WorkflowTaskTemplateService } from 'services/workflow-task-template.service';
import { StateController } from 'state-controller';
import { toSeconds, toHoursMinutesAndSeconds } from 'utils/to-hours-minutes/to-hours-minutes';
import { t } from 'setup-localization';
import { MODALS } from 'modules/root-modals/modals';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { validators } from 'utils/validator';
import { PriorityEnum } from 'types/priority-enums';
import { FileItem, IdName } from 'types/common-types';
import { WorkflowTaskTemplateFileService } from 'services/workflow-task-template-files.service';
import { WorkflowTaskTemplateResponsibilityService } from 'services/workflow-task-template-responsibility.service';
import FireMinusIcon from 'icons/fire-minus';
import { ResponsibilityData } from 'services/workflow-task-template-responsibility.model';
import { Actions as WorkflowTaskTemplateResponsibilityModalActions } from 'modules/task-template-responsibility-modal/workflow-task-template-responsibility-modal.controller';
import { Actions as ProductWorkFlowLayoutActions } from 'pages/product-flow/product-flow-layout/product-flow-layout.controller';
import { ProductsService } from 'services/products.service';
import { ProductVersion } from 'services/products.model';
import { versionName } from 'utils/version-name';
import { WorkflowTemplateBonusesService } from 'services/workflow-task-template-bonuses.service';

export type WorkflowTaskTemplateInputsParamsKey = 'basic_reward' | 'is_additional' | 'hours' | 'minutes' | 'seconds' | 'priority';
export type WorkflowTaskTimeInputsParams = {
  hours?: number;
  minutes?: number;
  seconds?: number;
};
export type WorkflowTaskParams = WorkflowTaskTimeInputsParams & {
  basic_reward?: number;
  priority?: string | IdName;
  prevValues?: {
    hours: number;
    minutes: number;
    seconds: number;
  };
};
export type WorkflowTaskFieldValidation = {
  hours: string;
  minutes: string;
  seconds: string;
  basic_reward: string;
};

export type WorkflowTaskTemplateState = {
  workflowTaskTemplate: WorkflowTaskTemplate &
    WorkflowTaskParams & {
      versions: ProductVersion[];
    };
  isLoading: boolean;
  isProcessing: boolean;
  isConfigFileLoading: boolean;
  isEditing: boolean;
  description: string;
  tags: TaskTag[];
  fieldsValidation: WorkflowTaskFieldValidation;
  paramsFields: WorkflowTaskParams;
};

const priorityArr: Array<PriorityEnum> = [
  PriorityEnum.Highest,
  PriorityEnum.High,
  PriorityEnum.Medium,
  PriorityEnum.Low,
  PriorityEnum.Lowest,
];

export const workflowTaskProprityOptions = priorityArr.map<IdName>((item) => ({
  name: item,
  id: item,
}));

export const TaskType = {
  WorkflowTask: {
    id: 'Workflow task',
    value: false,
    name: 'Workflow task',
  },
  AdditionalTask: {
    id: 'Additional task',
    value: true,
    name: 'Additional task',
  },
};

const defaultState: WorkflowTaskTemplateState = {
  workflowTaskTemplate: {
    id: '',
    name: '',
    description: '',
    basic_reward: 0,
    time_limit: 0,
    priority: '',
    product_id: '',
    is_additional: false,
    workflow_template_id: '',
    workflowTaskTemplateTagRelation: [],
    workflowTaskTemplateFiles: [],
    responsibility: [],
    bonuses: {
      parameter_bonuses: [],
      combination_bonuses: [],
    },
    path: [],
    hours: 0,
    minutes: 0,
    previousTasks: [],
    followingTasks: [],
    product: {
      id: '',
      name: '',
      published_at: '',
      version: 0,
      product_draft_id: '',
      is_active: false,
    },
    workflowTemplate: {
      id: '',
      name: '',
    },
    breadcrumbs: [],
    versions: [],
  },
  description: '',
  isLoading: false,
  isEditing: false,
  isConfigFileLoading: false,
  isProcessing: false,
  tags: [],
  fieldsValidation: {
    hours: '',
    minutes: '',
    seconds: '',
    basic_reward: '',
  },
  paramsFields: {
    hours: 0,
    minutes: 0,
    seconds: 0,
    basic_reward: 0,
    priority: {
      id: '',
      name: '',
    },
    prevValues: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  },
};

const stateController = new StateController<WorkflowTaskTemplateState>('WORKFLOW_TASK_TEMPLATE', defaultState);

export class Actions {
  public static loadTags() {
    return async (dispatch) => {
      const tags = await TaskTagsService.getAllTaskTags();

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          tags,
        })),
      );
    };
  }

  public static init(id: string) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const workflowTaskTemplate = await WorkflowTaskTemplateService.getWorkflowTaskTemplateInfoById(id);
        dispatch(ProductWorkFlowLayoutActions.setProductRoad(workflowTaskTemplate.id, workflowTaskTemplate.name));
        await dispatch(Actions.loadTags());

        const { basic_reward, priority } = workflowTaskTemplate;
        const { hours, minutes, seconds } = toHoursMinutesAndSeconds(workflowTaskTemplate.time_limit);

        const { id: productId, published_at, version, is_active } = workflowTaskTemplate.product;

        const versions = [
          {
            id: productId,
            is_main_version: false,
            is_published: true,
            published_at,
            is_active,
            version: versionName(version),
            processed_count: 1,
          },
        ];

        const sortedFiles = workflowTaskTemplate.workflowTaskTemplateFiles.sort((a, b) => a.name.localeCompare(b.name));

        dispatch(
          stateController.setState({
            workflowTaskTemplate: {
              ...workflowTaskTemplate,
              workflowTaskTemplateFiles: sortedFiles,
              hours,
              minutes,
              seconds,
              versions,
              product: workflowTaskTemplate.product,
            },
            description: workflowTaskTemplate.description,
            paramsFields: {
              basic_reward,
              priority: workflowTaskProprityOptions.find((item) => item.id === priority) || '',
              hours,
              minutes,
              seconds,
              prevValues: {
                hours,
                minutes,
                seconds,
              },
            },
          }),
        );
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static disposeState() {
    return async (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static updateWorkflowTaskTemplate(data: Partial<UpdateWorkflowTaskTemplate & WorkflowTaskTimeInputsParams>) {
    return async (dispatch, getState: () => AppState) => {
      const { id, name, description, priority, is_control, time_limit, is_additional, basic_reward, product_id } =
        getState().workflow_task_template.workflowTaskTemplate;
      const { hours, minutes, seconds } = getState().workflow_task_template.paramsFields;
      let timeLimitNew = time_limit;

      if (data.hours !== undefined) {
        timeLimitNew = toSeconds(data.hours, minutes, seconds);
      } else if (data.minutes !== undefined) {
        timeLimitNew = toSeconds(hours, data.minutes, seconds);
      } else if (data.seconds !== undefined) {
        timeLimitNew = toSeconds(hours, minutes, data.seconds);
      }

      const originalWorkflowTaskTemplate = {
        name,
        description,
        priority,
        is_control,
        time_limit,
        basic_reward,
        is_additional,
      };

      try {
        const workflowTaskTemplateData: UpdateWorkflowTaskTemplate = {
          ...originalWorkflowTaskTemplate,
        };
        Object.keys(data).forEach((key) => {
          if (key !== 'hours' && key !== 'minutes' && key !== 'seconds') {
            workflowTaskTemplateData[key] = data[key];
          }
        });

        workflowTaskTemplateData.time_limit = timeLimitNew;
        dispatch(stateController.setState({ isProcessing: true }));
        await WorkflowTaskTemplateService.updateWorkflowTaskTemplate(id, workflowTaskTemplateData);
        await ProductsService.updateProduct(product_id);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              ...workflowTaskTemplateData,
            },
            paramsFields: {
              ...prev.paramsFields,
              prevValues: {
                hours,
                minutes,
                seconds,
              },
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              ...originalWorkflowTaskTemplate,
            },
          })),
        );
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  // Name Change
  public static onWorkflowTaskTemplateNameChange(name: string) {
    return (dispatch) => {
      dispatch(Actions.updateWorkflowTaskTemplate({ name }));
    };
  }

  // Description Change
  public static onWorkflowTaskTemplateDescriptionChange(description: string) {
    return (dispatch) => {
      dispatch(Actions.updateWorkflowTaskTemplate({ description }));
    };
  }

  public static setDescription(text: string) {
    return (dispatch) => {
      dispatch(stateController.setState({ description: text }));
    };
  }

  public static toggleIsEditing() {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, isEditing: !prev.isEditing })));
    };
  }

  // Params Change
  public static updateInputParams(key: WorkflowTaskTemplateInputsParamsKey, value: string | number) {
    return (dispatch, getState: () => AppState) => {
      const { fieldsValidation } = getState().workflow_task_template;
      const prevValue = getState().workflow_task_template.paramsFields.prevValues[key];

      if (!fieldsValidation[key] && value !== prevValue) {
        dispatch(Actions.updateWorkflowTaskTemplate({ [key]: value }));
      }
    };
  }

  public static updateSelectParams(key: WorkflowTaskTemplateInputsParamsKey, value: IdName) {
    return async (dispatch) => {
      await dispatch(Actions.onFieldChange({ [key]: value }));
      dispatch(Actions.updateWorkflowTaskTemplate({ [key]: value.id }));
    };
  }

  public static updateTypeSelectParams(key: WorkflowTaskTemplateInputsParamsKey, value: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const { is_additional, name } = getState().workflow_task_template.workflowTaskTemplate;
      await dispatch(Actions.onFieldChange({ [key]: value }));
      if (is_additional) {
        dispatch(Actions.updateWorkflowTaskTemplate({ [key]: value }));
      } else {
        dispatch(Actions.openChangToAdditionalTaskModal(key, value, name));
      }
    };
  }

  public static openChangToAdditionalTaskModal(key: WorkflowTaskTemplateInputsParamsKey, value: boolean, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Change task type?',
            text: (
              <div style={{ marginBottom: '7px' }}>
                Changing the task type of <strong>{name}</strong> to an additional type will delete all its relations with other
                tasks in the workflow. <br /> Are you sure you want to change the task type?
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Change',
            action: () => dispatch(Actions.updateWorkflowTaskTemplate({ [key]: value })),
          },
        }),
      );
    };
  }

  public static onFieldChange(value: Partial<WorkflowTaskParams>) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          paramsFields: {
            ...prevState.paramsFields,
            ...value,
          },
        })),
      );
      dispatch(Actions.taskValidation());
    };
  }

  public static taskValidation() {
    return (dispatch, getState: () => AppState) => {
      const { fieldsValidation, paramsFields } = getState().workflow_task_template;
      const fieldsValidated = {};

      Object.keys(fieldsValidation).forEach((key) => {
        const [, errorMessage] = validators.isPositive(paramsFields[key], `The value must not be less than 0`);
        fieldsValidated[key] = errorMessage;
      });
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          fieldsValidation: fieldsValidated,
        })),
      );
    };
  }

  // Tags Change
  public static onCreateWorkflowTaskTemplateTag(name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const originalTags = getState().workflow_task_template.tags;
      const { product_id } = getState().workflow_task_template.workflowTaskTemplate;

      try {
        const newTag = await TaskTagsService.createTaskTag({ name });
        await ProductsService.updateProduct(product_id);
        dispatch(stateController.setState((prev) => ({ ...prev, tags: [...prev.tags, newTag] })));

        return newTag;
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, tags: originalTags })));
        return null;
      }
    };
  }

  public static onAddWorkflowTaskTemplateTagRelationTemplate(name: string, index: number) {
    return (dispatch, getState: GetStateFunction) => {
      const workflowTaskTemplateTagsRelationsTemplates =
        getState().workflow_task_template.workflowTaskTemplate.workflowTaskTemplateTagRelation.filter((item) =>
          item.id.includes('new-'),
        );
      const workflowTaskTemplateId = getState().workflow_task_template.workflowTaskTemplate.id;
      const workflowTaskTemplateTagRelationTemporaryId = `new-${index}`;
      const taskTagTemporaryId = uuidv4();

      const newTag: TaskTag = {
        id: taskTagTemporaryId,
        name,
      };

      const newWorkflowTaskTemplateTagRelationTemplate = {
        id: workflowTaskTemplateTagRelationTemporaryId,
        workflow_task_template_id: workflowTaskTemplateId,
        task_tag_id: taskTagTemporaryId,
        taskTag: newTag,
      };

      if (workflowTaskTemplateTagsRelationsTemplates.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflow_task_template_tag_relations: prev.workflowTaskTemplate.workflowTaskTemplateTagRelation.filter(
              (item) => !item.id.includes('new-'),
            ),
          })),
        );
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplate: [
            ...prev.workflowTaskTemplate.workflowTaskTemplateTagRelation,
            newWorkflowTaskTemplateTagRelationTemplate,
          ],
        })),
      );
    };
  }

  public static onCreateWorkflowTaskTemplateTagRelation(workflowTaskTemplateTag: TaskTag, index?: number) {
    return async (dispatch, getState: () => AppState) => {
      const { id, workflowTaskTemplateTagRelation, product_id } = getState().workflow_task_template.workflowTaskTemplate;

      const tagTemplateId =
        index !== undefined ? workflowTaskTemplateTagRelation.find((item) => item.id === `new-${index}`)?.id : null;

      try {
        const taskTemplateTagRelation = await WorkflowTaskTemplateService.createWorkflowTaskTemplateTagRelation({
          workflow_task_template_id: id,
          task_tag_id: workflowTaskTemplateTag.id,
        });
        await ProductsService.updateProduct(product_id);

        const newTaskTemplateTagRelations: WorkflowTaskTemplateTagRelation[] = !tagTemplateId
          ? [...workflowTaskTemplateTagRelation, { ...taskTemplateTagRelation, taskTag: { ...workflowTaskTemplateTag } }]
          : workflowTaskTemplateTagRelation.map((item) => {
              const isTemplate = item.id === tagTemplateId;

              if (!isTemplate) return item;

              return {
                ...taskTemplateTagRelation,
                taskTag: { id: workflowTaskTemplateTag.id, name: workflowTaskTemplateTag.name },
              };
            });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: { ...prev.workflowTaskTemplate, workflowTaskTemplateTagRelation: newTaskTemplateTagRelations },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: { ...prev.workflowTaskTemplate, workflowTaskTemplateTagRelation },
          })),
        );
      }
    };
  }

  public static deleteWorkflowTaskTemplateTagRelation(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const currentWorkflowTaskTemplateTagsRelations =
        getState().workflow_task_template.workflowTaskTemplate.workflowTaskTemplateTagRelation;
      const { product_id } = getState().workflow_task_template.workflowTaskTemplate;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              workflowTaskTemplateTagRelation: prev.workflowTaskTemplate.workflowTaskTemplateTagRelation.filter(
                (item) => item.id !== id,
              ),
            },
          })),
        );
        await WorkflowTaskTemplateService.deleteWorkflowTaskTemplateTagRelation(id);
        await ProductsService.updateProduct(product_id);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              workflowTaskTemplateTagRelation: currentWorkflowTaskTemplateTagsRelations,
            },
          })),
        );
      }
    };
  }

  public static onClearWorkflowTaskTemplateTagRelationsTemplates() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplate: {
            ...prev.workflowTaskTemplate,
            workflowTaskTemplateTagRelation: prev.workflowTaskTemplate.workflowTaskTemplateTagRelation.filter(
              (item) => !item.id.includes('new-'),
            ),
          },
        })),
      );
    };
  }

  // Workflow Task Template files

  public static deleteWorkflowTaskTemplateFile(fileId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflowTaskTemplate } = getState().workflow_task_template;

      try {
        if (!fileId.includes('new-')) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              isConfigFileLoading: true,
            })),
          );
          await WorkflowTaskTemplateFileService.delete(fileId);
          await WorkflowTaskTemplateService.updateWorkflowTaskTemplate(workflowTaskTemplate.id, workflowTaskTemplate);
          await ProductsService.updateProduct(workflowTaskTemplate.product_id);
        }
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...workflowTaskTemplate,
              workflowTaskTemplateFiles: workflowTaskTemplate.workflowTaskTemplateFiles.filter((file) => file.id !== fileId),
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isConfigFileLoading: false,
          })),
        );
      }
    };
  }

  public static openDeleteWorkflowTaskTemplateModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('product_flow.delete_product_file_modal.title')}</>,
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete the file <strong>{name}</strong>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteWorkflowTaskTemplateFile(id)),
          },
        }),
      );
    };
  }

  public static uploadFile(files: File[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflowTaskTemplate } = getState().workflow_task_template;
      if (!files.length) {
        return;
      }

      const onProgress = (e, id) => {
        const newProgress = Math.round((e.loaded / e.total) * 100);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...workflowTaskTemplate,
              workflowTaskTemplateFiles: prev.workflowTaskTemplate.workflowTaskTemplateFiles.map((item) => {
                if (item.id === id) {
                  return {
                    ...item,
                    progress: newProgress,
                  };
                }
                return item;
              }),
            },
          })),
        );
      };

      const mockFiles: FileItem[] = files.map((file) => ({
        id: `new-${uuidv4()}`,
        name: file.name,
        link: '',
        is_show_by_default: false,
        isUploading: true,
        progress: 0,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplate: {
            ...workflowTaskTemplate,
            workflowTaskTemplateFiles: [...workflowTaskTemplate.workflowTaskTemplateFiles, ...mockFiles].sort((a, b) =>
              a.name.localeCompare(b.name),
            ),
          },
        })),
      );
      const promises = files.map(async (file, i) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('workflow_task_template_id', workflowTaskTemplate.id);

        const newFile = await WorkflowTaskTemplateFileService.create(formData, (e) => onProgress(e, mockFiles[i].id));

        await WorkflowTaskTemplateService.updateWorkflowTaskTemplate(workflowTaskTemplate.id, workflowTaskTemplate);
        await ProductsService.updateProduct(workflowTaskTemplate.product_id);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              workflowTaskTemplateFiles: prev.workflowTaskTemplate.workflowTaskTemplateFiles.map((item) => {
                return mockFiles[i]?.id === item.id ? newFile : item;
              }),
            },
          })),
        );
      });
      await Promise.all(promises);
    };
  }

  // RESPONSIBILITIES

  public static createResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access } = getState().workflow_task_template_responsibility_modal;
      const { id, responsibility, product_id } = getState().workflow_task_template.workflowTaskTemplate;

      const getIdArray = <T extends { id: string }[]>(array: T) => array.map((item) => item.id);

      const data: ResponsibilityData = {
        name: general.name,
        task_template_id: id,
        reward_id: null,
        assigment_type: general.type,
        responsibility_count: general.workersCount,
        departments: getIdArray(access.selectedDepartments),
        positionTypes: getIdArray(access.selectedPositions),
        users: getIdArray(access.selectedWorkers),
      };

      try {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());
        const responsibilityData = await WorkflowTaskTemplateResponsibilityService.create(data);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              responsibility: [...prev.workflowTaskTemplate.responsibility, responsibilityData],
            },
          })),
        );
        await ProductsService.updateProduct(product_id);
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(responsibility));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              responsibility: [...responsibility],
            },
          })),
        );

        throw error;
      }
    };
  }

  public static updateResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access } = getState().workflow_task_template_responsibility_modal;
      const { id: taskTemplateId, responsibility, product_id } = getState().workflow_task_template.workflowTaskTemplate;
      const { currentId } = getState().workflow_task_template_responsibility_modal;

      const originalResponsibility = responsibility.find((item) => item.id === currentId);

      const getIdArray = <T extends { id: string }[]>(array: T) => array.map((item) => item.id);

      const data: ResponsibilityData = {
        name: general.name,
        task_template_id: taskTemplateId,
        reward_id: null,
        assigment_type: general.type,
        responsibility_count: general.workersCount,
        departments: getIdArray(access.selectedDepartments),
        positionTypes: getIdArray(access.selectedPositions),
        users: getIdArray(access.selectedWorkers),
        workflowTaskTemplateDepartmentRelations: access.selectedDepartments.map((item) => ({
          id: null,
          workflow_task_template_responsibility_id: null,
          department_id: item.id,
          department: {
            id: item.id,
            name: item.name,
            users_count: item.users_count,
          },
        })),
        workflowTaskTemplatePositionTypeRelations: access.selectedPositions.map((item) => ({
          id: null,
          workflow_task_template_responsibility_id: null,
          position_type_id: item.id,
          positionType: {
            id: item.id,
            name: item.name,
            users_count: item.users_count,
          },
        })),
        workflowTaskTemplateUserRelations: access.selectedWorkers.map((item) => ({
          id: null,
          workflow_task_template_responsibility_id: null,
          user_id: item.id,
          user: {
            id: item.id,
            first_name: item.first_name,
            last_name: item.last_name,
            avatar_image_url: item.avatar_image_url,
          },
        })),
      };

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              // eslint-disable-next-line no-confusing-arrow
              responsibility: prev.workflowTaskTemplate.responsibility.map((item) =>
                item.id === currentId ? { ...item, ...data } : item,
              ),
            },
          })),
        );
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());

        await WorkflowTaskTemplateResponsibilityService.update(currentId, data);
        await ProductsService.updateProduct(product_id);
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(responsibility));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              // eslint-disable-next-line no-confusing-arrow
              responsibility: prev.workflowTaskTemplate.responsibility.map((item) =>
                item.id === currentId ? originalResponsibility : item,
              ),
            },
          })),
        );

        throw error;
      }
    };
  }

  public static deleteResponsibility(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { responsibility, product_id } = getState().workflow_task_template.workflowTaskTemplate;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              responsibility: prev.workflowTaskTemplate.responsibility.filter((item) => item.id !== id),
            },
          })),
        );

        await WorkflowTaskTemplateResponsibilityService.delete(id);
        await ProductsService.updateProduct(product_id);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            workflowTaskTemplate: {
              ...prev.workflowTaskTemplate,
              responsibility: [...responsibility],
            },
          })),
        );

        throw error;
      }
    };
  }

  public static openDeleteResponsibilityConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete responsibility',
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete <strong>{name}</strong>
                <br />
                You cannot undo this action.
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteResponsibility(id)),
          },
        }),
      );
    };
  }

  // WORKFLOW TASK TEMPLATE BONUSES

  public static deleteBonus(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { bonuses, product_id } = getState().workflow_task_template.workflowTaskTemplate;
      const filteredParameters = bonuses.parameter_bonuses.filter((parameter) => parameter.id !== id);
      const filteredCombination = bonuses.combination_bonuses.filter((parameter) => parameter.id !== id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplate: {
            ...prev.workflowTaskTemplate,
            bonuses: {
              ...prev.workflowTaskTemplate.bonuses,
              combination_bonuses: filteredCombination,
              parameter_bonuses: filteredParameters,
            },
          },
        })),
      );

      await ProductsService.updateProduct(product_id);
      await WorkflowTemplateBonusesService.delete(id);
    };
  }

  public static updateTemplateOptions(options: TaskTemplateBonus) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          workflowTaskTemplate: {
            ...prev.workflowTaskTemplate,
            bonuses: options,
          },
        })),
      );
    };
  }
}

export class Selectors {}

export const reducer = stateController.getReducer();
