import { FC, ReactNode } from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import s from './avatar-circle.module.scss';

const getAbbreviation = (firstName: string, lastName: string) => `${firstName[0]}${lastName[0]}`.toUpperCase();

type AvatarCircleProps = AvatarProps & {
  isEmpty: boolean;
  lastName?: string;
  image?: string | ReactNode;
  className?: string;
  firstName?: string;
  onClick?: () => void;
};

export const AvatarCircle: FC<AvatarCircleProps> = ({ isEmpty, lastName, image, firstName, className, ...rest }) => {
  if (isEmpty) {
    return <Avatar className={`${s.avatar} ${s.empty_mode} ${className}`} {...rest} />;
  }

  const content =
    typeof image === 'string' ? (
      <Avatar className={`${s.avatar} ${className}`} src={image} {...rest} />
    ) : (
      <Avatar className={`${s.avatar} ${className}`} {...rest}>
        {image || getAbbreviation(firstName, lastName)}
      </Avatar>
    );

  return content;
};
