export enum TenantUserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export interface Tenant {
  id: string;
  name: string;
  subdomain: string;
  logo?: string;
  user_status: TenantUserStatus;
}
