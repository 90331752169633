import { NavLink } from 'react-router-dom';
import Button from 'components/button/button';
import { TypographyProps } from '@mui/material';
import { HomeFilledIcon } from 'icons/home-filled';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { NavMenuOpen2Icon } from 'icons/nav-menu-open-2';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';
import React, { ReactNode, useRef, useEffect, useState } from 'react';
import { getBreadcrumbWidth } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import BreadcrumbItem from 'components/ui-new/breadcrumbs/components/breadcrumb-item/breadcrumb-item';
import s from './breadcrumbs.module.scss';

export type Breadcrumb = {
  label: string;
  route?: string;
  icon?: ReactNode;
  tooltip?: string;
  disabled?: boolean;
  isDeleted?: boolean;
  forceActive?: boolean;
  onClick?: VoidFunction;
  isAllowedCopy?: boolean;
  forceDisactive?: boolean;
  additionalContent?: JSX.Element;
};

type OwnProps = {
  maxWidth?: number;
  separator?: ReactNode;
  isClickable?: boolean;
  withNavButton?: boolean;
  items: Array<Breadcrumb>;
  withHomeButton?: boolean;
  onNavBack?: VoidFunction;
  coloredLastElement?: boolean;
  typographyProps?: TypographyProps;
};

const Breadcrumbs: React.FC<OwnProps> = ({
  items,
  maxWidth,
  separator,
  typographyProps,
  isClickable = true,
  withNavButton = true,
  withHomeButton = true,
  coloredLastElement = true,
  onNavBack = () => window.history.back(),
}) => {
  const refArray = useRef<any[]>([]);
  const refRow = useRef<HTMLDivElement>(null);

  const [forceRender, setForceRender] = useState(false);

  const max = maxWidth ? maxWidth - 30 : null; // 30px - place for "copy icon"

  const handleStorageChange = () => {
    if (localStorage.getItem('isMenuOpen') === 'true') {
      const newMax = maxWidth - 190;
      while (refRow.current.clientWidth > newMax) {
        getBreadcrumbWidth(newMax, refRow, refArray);
      }
    } else if (localStorage.getItem('isMenuOpen') === 'false') {
      [...Array(3)].forEach(() => {
        getBreadcrumbWidth(max, refRow, refArray);
      });
    }
    setForceRender((prevState) => !prevState);
  };

  useEffect(() => {
    if (refRow && max && refArray.current.length) {
      while (refRow.current.clientWidth > max) {
        getBreadcrumbWidth(max, refRow, refArray);
      }

      if (refRow.current.clientWidth <= max) {
        // forced rendering is required to correctly calculate width and display tooltips after reload page
        setForceRender((prevState) => !prevState);
      }

      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('resize', () => getBreadcrumbWidth(max, refRow, refArray));
    }

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('resize', () => getBreadcrumbWidth(max, refRow, refArray));
    };
  }, [refArray?.current?.length, refRow, max, items.length]);

  const breadcrumbs = React.useMemo(
    () =>
      items.reduce((arr, item, index) => {
        const isLast = index === items.length - 1;
        const isActive = (isLast || item.forceActive) && !item.forceDisactive && coloredLastElement;
        const itemRef = React.createRef();

        arr.push(
          <BreadcrumbItem
            ref={itemRef}
            icon={item.icon}
            label={item.label}
            route={item.route}
            isActive={isActive}
            tooltip={item.tooltip}
            onClick={item.onClick}
            disabled={item.disabled}
            isDeleted={item.isDeleted}
            key={item.route + item.label}
            typographyProps={typographyProps}
            isAllowedCopy={item.isAllowedCopy}
            additionalContent={item.additionalContent}
            isClickable={isClickable && !item.disabled}
          />,
        );

        refArray.current[index] = itemRef;

        return arr;
      }, []),
    [items, typographyProps, refArray, refRow, max, forceRender],
  );

  return (
    <div className={s.row} ref={refRow}>
      {withNavButton && (
        <Button
          sx={{ width: '28px', height: '28px', minWidth: 'unset', padding: '7px' }}
          variant="outlined"
          color="secondary"
          size="XXS"
          onClick={onNavBack}
        >
          <ArrowLongLeftIcon />
        </Button>
      )}
      <MuiBreadcrumbs maxItems={100} separator={separator || <NavMenuOpen2Icon />} aria-label="breadcrumb">
        {withHomeButton && (
          <NavLink key="home" to="/" className={`${s.breadcrumb_link} ${s.ordinary}`}>
            <HomeFilledIcon fill="#878B92" width="16" height="16" className={s.icon} />
          </NavLink>
        )}

        {breadcrumbs}
      </MuiBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
