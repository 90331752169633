import { Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import styles from './custom-chip.module.scss';

type Props = {
  tooltipTitle?: string;
  text: string;
  icon?: ReactNode;
  className?: string;
  type: 'red';
};

const CustomChip = ({ tooltipTitle = '', text, icon, className = '', type }: Props) => {
  return (
    <Tooltip title={tooltipTitle} placement="top-start" className={className}>
      <span className={styles[type]}>
        {icon || ''}
        {text}
      </span>
    </Tooltip>
  );
};

export default CustomChip;
