import { FC, SVGProps } from 'react';

export const DoneIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8" cy="8" r="6.4" stroke="#91979F" strokeWidth="1.2" />
    <path
      d="M4.93335 8.1938L7.07246 10.3329L11.3583 6.05469"
      stroke="#91979F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
