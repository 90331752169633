import { TaskData as ProductionWorkflowTaskData } from 'pages/production-workflow/controllers/production-workflow.controller';
import { AppState } from 'redux/store';
import { TaskData as ManageFailedTasksTaskData } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { getDateWithTime } from 'utils/time';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { useDispatch, useSelector } from 'react-redux';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';

export const useTaskNode = (id: string, data: ProductionWorkflowTaskData | ManageFailedTasksTaskData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isManageFailedTasks = 'isFail' in data;
  const isTaskPage = 'page' in data && data.page === 'task';
  const finishedAt = !isManageFailedTasks && data.finishedAt ? getDateWithTime(data.finishedAt) : '-';
  const isProductionDone = !isManageFailedTasks ? data.productionStatus === ProductionStatusEnum.Done : undefined;
  const isProductionCanceled = !isManageFailedTasks ? data.productionStatus === ProductionStatusEnum.Canceled : undefined;
  const statusTooltip =
    isProductionDone || (isProductionCanceled && (data.status === TaskStatusEnum.Done || data.status === TaskStatusEnum.Canceled))
      ? `${data.status === TaskStatusEnum.Done ? 'Done' : 'Canceled'} ${finishedAt}`
      : undefined;

  const usersWithSuggested = useSelector((state: AppState) => {
    return isTaskPage ? state.task.task.perfomers.users : state.production_workflow.usersWithSuggested;
  });

  const { edges } = useSelector((state: AppState) => {
    return isTaskPage ? state.manage_failed_tasks : state.production_workflow;
  });
  const users = useSelector((state: AppState) => {
    return isTaskPage
      ? [...state.task.task.perfomers.users.suggestedUsers, ...state.task.task.perfomers.users.suggestedUsers]
      : state.production_workflow.users;
  });

  const hasTarget = edges.some((edge) => edge.target === id);
  const hasSource = edges.some((edge) => edge.source === id);
  const combinedData =
    data.responsibilities.flatMap((responsibility) =>
      responsibility.taskSlots.map((slot) => {
        const tooltipMessage = slot.taskAssignment === null ? getTestMessage(responsibility.assigment_type) : '';

        const userSlot = {
          id: slot.id,
          tooltip: tooltipMessage,
          responsibilityId: slot.task_responsibility_id,
          assignedUserId: slot.taskAssignment?.user?.id,
          last_name: slot.taskAssignment?.user?.last_name,
          first_name: slot.taskAssignment?.user?.first_name,
          avatar_image_url: slot.taskAssignment?.user?.avatar_image_url,
          department: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.department,
          position: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.position_type,
        };

        return { userSlot };
      }),
    ) || [];

  function getTestMessage(assignmentType) {
    switch (assignmentType) {
      case AssignmentType.Manual:
        return 'Manual assignment was set up';
      case AssignmentType.Self_Assignment:
        return 'Self-assignment was set up';
      case AssignmentType.Auto:
        return 'Automatic assignment was set up';
      default:
        return '';
    }
  }
  const userSlots = combinedData.map((dataItem) => dataItem.userSlot);

  const handleNavigate = () => {
    if (isManageFailedTasks) {
      window.open(`${window.location.origin}${Paths.Task}/${data.id}`, '_blank');
    } else {
      navigate(`/task/${data.id}`);
    }
  };

  return {
    users,
    hasTarget,
    hasSource,
    userSlots,
    statusTooltip,
    isManageFailedTasks,
    usersWithSuggested,
    dispatch,
    handleNavigate,
  };
};
