import { FC } from 'react';
import MoveToIcon from 'icons/move-to';
import { TrashIcon } from 'icons/trash';
import { Position2Icon } from 'icons/position-2';
import Checkbox from 'components/checkbox/checkbox';
import EditPencilUnderlineIcon from 'icons/edit-pencil-underline';
import DropdownMenu from 'components/dropdown-menu/dropdown-menu';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import { PositionSlotByDepartmentId as PositionItemType } from 'services/position-slots.model';
import { DepartmentItem as DepartmentItemType } from 'pages/departments/departments.controller';
import HorizontalShiftAnimation from 'components/wrapper-with-shift-animation/horisontal-shift-animation';
import { NoUserIcon } from 'icons/no-user';
import s from './position-item.module.scss';

type OwnProps = {
  isSelected: boolean;
  data: PositionItemType;
  lastAddedItemId: string;
  isMultiselectMode: boolean;
  selectElement: (id: string) => void;
  deselectElement: (id: string) => void;
  onEditItem: (item?: PositionItemType) => void;
  onMoveItem: (item: PositionItemType | DepartmentItemType) => void;
  onDeleteItem: (item: PositionItemType | DepartmentItemType) => void;
};

const PositionItem: FC<OwnProps> = ({
  data,
  isSelected,
  lastAddedItemId,
  isMultiselectMode,
  onEditItem,
  onMoveItem,
  onDeleteItem,
  selectElement,
  deselectElement,
}) => {
  const handleSelect = (elementId: string) => {
    if (isSelected) {
      deselectElement(elementId);
    } else {
      selectElement(elementId);
    }
  };

  const menuItems = [
    {
      onClick: () => onEditItem(data),
      key: 'action_manage_position',
      text: 'Manage',
      icon: <EditPencilUnderlineIcon width={16} height={16} />,
    },
    {
      onClick: () => onMoveItem(data),
      key: 'action_move_to',
      text: 'Move to',
      icon: <MoveToIcon width={16} height={16} />,
    },
    {
      onClick: () => onDeleteItem(data),
      key: 'action_delete_position',
      text: 'Delete',
      icon: <TrashIcon width={16} height={16} />,
    },
  ];
  const user = data.users[0];

  return (
    <div className={s.container} id={`dep-${data.id}`} data-highlighted={lastAddedItemId === data.id}>
      <HorizontalShiftAnimation
        animationTime={0.28}
        maxWidthWhenIsShown={31}
        isVisible={isMultiselectMode}
        componentClassName={s.checkbox_wrapper}
      >
        <div className={s.checkbox}>
          <Checkbox
            size="medium"
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              handleSelect(data.id);
            }}
          />
        </div>
      </HorizontalShiftAnimation>
      <div className={s.grid}>
        <div className={s.icon}>
          <Position2Icon />
        </div>
        <div className={s.position_type}>
          <span>{data.positionType.name}</span>
          <span>{data.name}</span>
        </div>
        <div className={s.user}>
          <AvatarCircle
            isEmpty={false}
            className={s.avatar}
            lastName={user?.last_name || 'user'}
            firstName={user?.first_name || 'No'}
            image={user?.avatar_image_url || <NoUserIcon />}
          />
          {`${user?.first_name || 'No'} ${user?.last_name || 'user'}`}
        </div>
        <div className={s.actions} data-hidden={isMultiselectMode}>
          <DropdownMenu items={menuItems} id="user-item-more-button" disabled={isMultiselectMode} />
        </div>
      </div>
    </div>
  );
};

export default PositionItem;
