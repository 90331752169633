import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import React, { forwardRef, LegacyRef } from 'react';
import HorizontalShiftAnimation from 'components/wrapper-with-shift-animation/horisontal-shift-animation';
import s from './navigation-link.module.scss';

type Props = {
  path: string;
  name: string;
  icon: React.FC;
  isExpandedView: boolean;
};

const Navigation = forwardRef((props: Props, ref: LegacyRef<HTMLDivElement>) => {
  const { isExpandedView, icon: Icon, ...rest } = props;
  return (
    <div {...rest} ref={ref}>
      <NavLink
        className={({ isActive }) => {
          return isActive
            ? `${isExpandedView ? s.active_link : s.active_link_collapsed}`
            : `${isExpandedView ? s.link : s.link_collapsed}`;
        }}
        to={props.path}
      >
        <span>
          <i className={s.icon}>
            <Icon />
          </i>
        </span>
        <HorizontalShiftAnimation
          animationTime={0.3}
          maxWidthWhenIsShown={149}
          isVisible={isExpandedView}
          componentClassName={s.animation_wrapper}
        >
          <div className={s.text}>{props.name}</div>
        </HorizontalShiftAnimation>
      </NavLink>
    </div>
  );
});

Navigation.displayName = 'Navigation';

export const NavigationLink = (props: Props) => {
  const { path, name, icon: Icon, isExpandedView } = props;
  return (
    <div>
      <Tooltip
        PopperProps={{
          sx: {
            left: '-10px !important',
          },
        }}
        title={name}
        placement="right"
        classes={{ tooltip: s.tooltip }}
        disableHoverListener={isExpandedView}
      >
        <Navigation path={path} name={name} icon={Icon} isExpandedView={isExpandedView} />
      </Tooltip>
    </div>
  );
};
