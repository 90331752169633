import { FC, SVGProps } from 'react';

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.8471 9.2627C19.8471 9.2627 19.2456 16.5431 18.8973 19.6113C18.7314 21.0763 17.8071 21.9347 16.2912 21.9617C13.4065 22.0126 10.5174 22.0158 7.63374 21.9563C6.17535 21.9271 5.26538 21.0579 5.10284 19.6189C4.75234 16.5247 4.15527 9.2627 4.15527 9.2627"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7638 5.77101C16.8958 5.77101 16.1473 5.17098 15.977 4.34066L15.7083 3.02492C15.5425 2.4184 14.9808 2 14.3406 2H9.66134C9.02115 2 8.45947 2.4184 8.29362 3.02492L8.02383 4.34066C7.85356 5.17098 7.10612 5.77101 6.23816 5.77101"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.375 5.77148H2.62488" stroke="#878B92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
