import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { updateWorkflowDetailsRequestBody } from 'services/production-workflow.model';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';

export const MAX_LETTERS = 1000;

export type EditNoteArgs = {
  initialNote: string;
  productionId: string;
};

export type EditNoteState = {
  note: string;
  isLoading: boolean;
  productionId: string;
  isConfigurationChange: boolean;
};

const defaultState: EditNoteState = {
  note: '',
  productionId: '',
  isLoading: false,
  isConfigurationChange: false,
};

const stateController = new StateController<EditNoteState>('EDIT_NOTE', defaultState);

export class EditNoteActions {
  public static init({ initialNote, productionId }: EditNoteArgs) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionId,
          note: initialNote,
        })),
      );
    };
  }

  public static dispose() {
    return async (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    };
  }

  public static saveChanges(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const { productionId, note, isConfigurationChange } = getState().production.editNote;
        let body: updateWorkflowDetailsRequestBody = {};

        if (isConfigurationChange) {
          body = { product_configuration: note };
        } else {
          body = { note };
        }
        await ProductionWorkflowService.updateProductionWorkflowDetails(productionId, body);

        if (page === Page.InfoDropdownWorkflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
          await loadData(productionId);
        }
        if (page === Page.InfoDropdownProduction) {
          const customGroupBy = getState().production.filters.groupBy;
          const { currentPage } = getState().production.filters.pagination;
          await dispatch(ProductionFiltersActions.getProductsByFilter({ currentPage, customGroupBy, showFetchEffect: false }));
          await loadData(productionId);
        }
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static onChange(value: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      if (value.length <= MAX_LETTERS) {
        dispatch(stateController.setState({ note: value }));
      }
    };
  }

  public static onChangeNoteMode(value: boolean) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      dispatch(stateController.setState({ isConfigurationChange: value }));
    };
  }
}

export const reducer = stateController.getReducer();
