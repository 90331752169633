import { useEffect, useState } from 'react';
import { IdName } from 'types/common-types';
import Checkbox from 'components/checkbox/checkbox';
import arrowRight from 'icons/emoji-png/arrow-right.png';
import { ProductionStatusEnum } from 'types/status-enums';
import { statuses } from 'components/status-selector/constants';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import s from './styles.module.scss';

type Props = {
  productionName: string;
  tasks: Array<IdName & { status: ProductionStatusEnum }>;
  components: Array<IdName & { status: ProductionStatusEnum }>;
  onChange: (tasksIds: string[], componentsIds: string[]) => void;
};

const CancelTasksBody = ({ tasks, productionName, components, onChange }: Props) => {
  const [tasksToCancel, setTasksToCancel] = useState(tasks.map((t) => t.id));
  const [componentsToCancel, setComponentsToCancel] = useState(components.map((c) => c.id));

  const handleTaskClick = (id: string) => {
    if (tasksToCancel.some((item) => item === id)) {
      setTasksToCancel((prev) => prev.filter((item) => item !== id));
      return;
    }
    setTasksToCancel((prev) => [...prev, id]);
  };

  const handleComponentClick = (id: string) => {
    if (componentsToCancel.some((item) => item === id)) {
      setComponentsToCancel((prev) => prev.filter((item) => item !== id));
      return;
    }
    setComponentsToCancel((prev) => [...prev, id]);
  };

  useEffect(() => {
    onChange(tasksToCancel, componentsToCancel);
  }, [onChange, tasksToCancel, componentsToCancel]);

  return (
    <Stack gap="3px">
      {components.length ? (
        <Typography>
          A canceling of the production process causes a blocking of non-finished components and tasks in it.
        </Typography>
      ) : (
        <Typography>A canceling of the production process causes a blocking of tasks in it.</Typography>
      )}

      {Boolean(components.length) && (
        <>
          <Typography>Following components with all their tasks will be canceled:</Typography>
          <div className={s.checkboxes_container}>
            {components.map(({ id, name, status }) => (
              <FormControlLabel
                key={id}
                className={s.row}
                control={
                  <Checkbox
                    size="medium"
                    color="error"
                    checked={componentsToCancel.some((item) => item === id)}
                    onChange={() => handleComponentClick(id)}
                  />
                }
                label={
                  <div className={s.list_item}>
                    <p className={s.name_container}>
                      <TextEllipsis maxWidth="100%" tooltipTitle={name}>
                        {name}
                      </TextEllipsis>
                    </p>
                    <div className={s.status_row}>
                      (<b className={s.bold_text}>{statuses[status].text}</b>&nbsp;
                      <img src={arrowRight} alt="arrow" className={s.icon} />
                      &nbsp;
                      <b className={s.bold_text}>Canceled</b>)
                    </div>
                  </div>
                }
              />
            ))}
          </div>
        </>
      )}

      {Boolean(tasks.length) && (
        <>
          <Typography>Following tasks will be canceled:</Typography>
          <div className={s.checkboxes_container}>
            {tasks.map(({ id: taskId, name, status: taskStatus }) => (
              <FormControlLabel
                key={taskId}
                className={s.row}
                control={
                  <Checkbox
                    size="medium"
                    color="error"
                    checked={tasksToCancel.some((item) => item === taskId)}
                    onChange={() => handleTaskClick(taskId)}
                  />
                }
                label={
                  <div className={s.list_item}>
                    <p className={s.name_container}>
                      <TextEllipsis maxWidth="100%" tooltipTitle={name}>
                        {name}
                      </TextEllipsis>
                    </p>
                    <div className={s.status_row}>
                      (<b className={s.bold_text}>{statuses[taskStatus].text}</b>&nbsp;
                      <img src={arrowRight} alt="arrow" className={s.icon} />
                      &nbsp;
                      <b className={s.bold_text}>Canceled</b>)
                    </div>
                  </div>
                }
              />
            ))}
          </div>
        </>
      )}

      <Typography>You can not undo this canceled production.</Typography>

      {components.length ? (
        <Typography>
          Are you sure you want to cancel <span className={s.bold_text}>{productionName}</span> production and selected components
          and tasks?
        </Typography>
      ) : (
        <Typography>
          Are you sure you want to cancel <span className={s.bold_text}>{productionName}</span> production and selected tasks?
        </Typography>
      )}
    </Stack>
  );
};

export default CancelTasksBody;
