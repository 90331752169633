import { baseAxiosInstance } from 'axios-config';
import { Filters, GetUsersWithFiltersResponse } from 'services/users-filter.model';

export class UserFilterService {
  public static async getUsersWithFilters(filters: Partial<Filters>, skip: number, take: number) {
    const { search, sort_by, order, active_filter, vacation_filter } = filters;
    const { data } = await baseAxiosInstance.get<GetUsersWithFiltersResponse>(
      `/users/all-by-page?search=${search}&sortBy=${sort_by.id}&order=${order}&active_filter=${active_filter}&vacation_filter=${vacation_filter}&take=${take}&skip=${skip}`,
    );
    return data;
  }
}
