import { FC } from 'react';
import { connect } from 'react-redux';
import s from './user-menu.module.scss';
import { Actions as AuthActions } from '../../../../../../redux/auth.controller';
import { LogOutIcon } from '../../../../../../icons/log-out';

type DispatchProps = {
  onLogout: () => void;
};

type OwnProps = {
  isUserMenuOpen: (isOpen: boolean) => void;
};

type Props = DispatchProps & OwnProps;

const UserMenu: FC<Props> = ({ onLogout, isUserMenuOpen }) => {
  return (
    <>
      <div className={s.backdrop} onClick={() => isUserMenuOpen(false)} />
      <div className={s.header}>
        <div className={s.info_container}>
          <div className={s.info_item}>
            <div onClick={onLogout} className={s.item}>
              <LogOutIcon />
              <span className={s.log_out_text}>Log out</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  onLogout: AuthActions.logout,
};

export default connect(null, mapDispatchToProps)(UserMenu);
