import { ToDoIcon } from 'icons/to-do';
import { Tooltip } from '@mui/material';
import s from './tag-outline.module.scss';

type RendererFunctionWithColors = () => JSX.Element;

type Props = {
  color: Colors;
  active?: boolean;
  disabled?: boolean;
  textOverflow?: boolean;
  text?: string | JSX.Element | RendererFunctionWithColors;
  iconLeft?: React.ReactElement | RendererFunctionWithColors;
  iconRight?: React.ReactElement | RendererFunctionWithColors;
  [key: string]: any;
  onClick?: VoidFunction;
};

type Colors =
  | 'red'
  | 'grey'
  | 'blue'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'violet'
  | 'purple'
  | 'light_orange'
  | 'grey_dark_text'
  | 'grey_dark_filled';

export default function Tag({
  color,
  text,
  active,
  disabled,
  iconLeft,
  onClick,
  textOverflow = false,
  iconRight,
  ...rest
}: Props) {
  const classNames = `${s.container} ${s[color]} ${onClick && !disabled ? s.pressable : ''} ${active ? s.active : ''} ${
    disabled ? s.disabled : ''
  } ${rest?.className}`;

  return (
    <div {...rest} className={classNames} onClick={onClick}>
      {iconLeft && <span className={`${s.icon} ${s[color]}`}>{typeof iconLeft === 'function' ? iconLeft() : iconLeft}</span>}
      {text && (
        <Tooltip title={typeof text === 'string' && text.length > 25 && textOverflow ? text : ''} placement="top-start">
          <span className={`${s.text} ${textOverflow ? s.text_ellipsis : ''} ${color}`}>
            {typeof text === 'function' ? text() : text}
          </span>
        </Tooltip>
      )}
      {iconRight && <span className={`${s.icon} ${color}`}>{typeof iconRight === 'function' ? iconRight() : iconRight}</span>}
    </div>
  );
}

export const TagsExample = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 30 }}>
      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', flexShrink: 0 }}>
        <Tag onClick={() => {}} text="Grey" color="grey" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Grey active" color="grey" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Grey disabled" color="grey" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Blue" color="blue" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Blue active" color="blue" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Blue disabled" color="blue" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Green" color="green" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Green active" color="green" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Green disabled" color="green" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Orange" color="orange" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Orange active" color="orange" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Orange disabled" color="orange" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Yellow" color="yellow" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Yellow active" color="yellow" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Yellow disabled" color="yellow" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Red" color="red" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Red active" color="red" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Red disabled" color="red" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Grey dark text" color="grey_dark_text" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Grey dark text active" color="grey_dark_text" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Grey dark text disabled" color="grey_dark_text" iconLeft={<ToDoIcon />} disabled />
      </div>

      <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
        <Tag onClick={() => {}} text="Grey dark filled" color="grey_dark_filled" iconLeft={<ToDoIcon />} />
        <Tag onClick={() => {}} text="Grey dark filled active" color="grey_dark_filled" iconLeft={<ToDoIcon />} active />
        <Tag onClick={() => {}} text="Grey dark filled disabled" color="grey_dark_filled" iconLeft={<ToDoIcon />} disabled />
      </div>
    </div>
  );
};
