import { StateController } from 'state-controller';
import { v4 as uuidV4 } from 'uuid';
import { AppState } from 'redux/store';
import { t } from 'setup-localization';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { MODALS } from 'modules/root-modals/modals';
import FireMinusIcon from 'icons/fire-minus';
import { Trans } from 'react-i18next';
import { notify } from 'notifications';
import { ProductionWorkflowTaskT } from 'services/production-workflow.model';
import { ProductionTaskService } from 'services/production-task.service';
import { TaskStatusEnum } from 'types/status-enums';
import { PriorityEnum } from 'types/priority-enums';
import { TaskAssigmentService } from 'services/task-assigment.service';
import { UserShortModel } from 'services/user.model';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { IdName } from 'types/common-types';

export type ProductionWorkflowTaskTWithIssues = ProductionWorkflowTaskT & {
  issues?: IdName[];
};

export type ProductionWorkflowAdditionalTasksState = {
  isOpen: boolean;
  isLoadingUsers: boolean;
  tasks: ProductionWorkflowTaskTWithIssues[];
};

const defaultState: ProductionWorkflowAdditionalTasksState = {
  tasks: [],
  isOpen: false,
  isLoadingUsers: false,
};

const stateController = new StateController<ProductionWorkflowAdditionalTasksState>(
  'PRODUCTION_WORKFLOW_ADDITIONAL_TASKS',
  defaultState,
);

export class Actions {
  public static onChange(values: Partial<ProductionWorkflowAdditionalTasksState>) {
    return (dispatch) => dispatch(stateController.setState((prev) => ({ ...prev, ...values })));
  }

  public static toggleAdditionalTasksModal() {
    return (dispatch) => dispatch(stateController.setState((prev) => ({ ...prev, isOpen: !prev.isOpen })));
  }

  public static addTemporaryAdditionalTask() {
    return async (dispatch, getState: () => AppState) => {
      const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webTaskEditLevel, accessLevels, []))) {
        return;
      }

      const { tasks } = getState().production_workflow_additional_tasks;
      const { id } = getState().production_workflow.productionInfo;

      const temporaryId = `new-${uuidV4()}`;

      const temporaryAdditionalTask = {
        name: '',
        id: temporaryId,
        basic_reward: 0,
        is_control: false,
        is_in_queue: false,
        is_reopened: false,
        is_additional: true,
        time_limit: 0,
        order: tasks.length,
        production_workflow_id: id,
        status: TaskStatusEnum.To_Do,
        priority: PriorityEnum.Medium,
        total_in_progress_overtime: 0,
        description: 'Additional Task',
        responsibilities: [
          {
            taskSlots: [
              {
                id: uuidV4(),
                task_responsibility_id: '',
                taskAssignment: { user: { id: '' } },
              },
            ],
          },
        ],
        issues: [],
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: [temporaryAdditionalTask, ...tasks],
        })),
      );
    };
  }

  public static createAdditionalTask(temporaryId: string, name: string) {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;
      const productionWorkflowId = getState().production_workflow.productionInfo.id;

      const temporaryAdditionalTask = tasks.find((task) => task.id === temporaryId);

      const additionalTask = await ProductionTaskService.createTask({ ...temporaryAdditionalTask, name });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: tasks.map((task) => (task.id === temporaryId ? { ...task, ...additionalTask } : task)),
        })),
      );

      dispatch(ProductionWorkflowActions.silentLoad({ id: productionWorkflowId, disableAdditionalTasksSet: true }));
    };
  }

  public static updateAdditionalTask(id: string, updates: Partial<ProductionWorkflowTaskT>) {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;
      const productionWorkflowId = getState().production_workflow.productionInfo.id;

      const currentAdditionalTask = tasks.find((workflow) => workflow.id === id);

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: tasks.map((task) => (task.id === id ? { ...task, ...updates } : task)),
          })),
        );

        await ProductionTaskService.updateTask(id, updates);
        if (updates.status) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionWorkflowId, disableAdditionalTasksSet: true }));
        }
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: tasks.map((task) => (task.id === id ? { ...task, ...currentAdditionalTask } : task)),
          })),
        );
      }
    };
  }

  public static deleteTemporaryAdditionalTask(temporaryId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;

      const filteredAdditionalTasks = tasks.filter((item) => item.id !== temporaryId);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: filteredAdditionalTasks,
        })),
      );
    };
  }

  public static deleteAdditionalTaskFromList(taskId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;
      const productionWorkflowId = getState().production_workflow.productionInfo.id;

      try {
        await ProductionTaskService.deleteTask(taskId);
        dispatch(Actions.onSetTasks(tasks.filter((item) => item.id !== taskId)));
        notify.success('Deleted successfully');
        dispatch(ProductionWorkflowActions.silentLoad({ id: productionWorkflowId, disableAdditionalTasksSet: true }));
      } catch (error) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            tasks,
          })),
        );
      }
    };
  }

  public static onSetTasks(workflowTaskTemplates: ProductionWorkflowTaskT[]) {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;

      let updatedWorkflowTaskTemplates = [...workflowTaskTemplates];
      const outOfOrder = updatedWorkflowTaskTemplates.some((item, index) => item.order !== index);

      if (outOfOrder) {
        updatedWorkflowTaskTemplates = updatedWorkflowTaskTemplates.map((item, index) => ({
          ...item,
          order: index,
        }));
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: updatedWorkflowTaskTemplates,
        })),
      );

      const manageOrder = updatedWorkflowTaskTemplates.map(({ id, order }) => ({ id, order }));

      try {
        await ProductionTaskService.manageTaskOrder({ tasks: manageOrder });
      } catch (err) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks,
          })),
        );
      }
    };
  }

  public static openDeleteConfirmationModal(taskId) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('additional_tasks.delete_additional_task_modal.title')}</>,
            text: (
              <Trans i18nKey="additional_tasks.delete_additional_task_modal.text">
                <div style={{ marginBottom: '7px' }}>Are you sure you want to delete the task?</div>,
              </Trans>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteAdditionalTaskFromList(taskId)),
          },
        }),
      );
    };
  }

  public static copyAdditionalTaskLink(id: string) {
    return async () => {
      const currentDomain = window.location.hostname;
      const link = `https://${currentDomain}/task-template/${id}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link copied');
    };
  }

  public static handleUser(taskId: string, user: UserShortModel, slotId: string, type: 'assign' | 'unassign') {
    return async (dispatch, getState: () => AppState) => {
      const { tasks } = getState().production_workflow_additional_tasks;
      const isAssign = type === 'assign';

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: prev.tasks.map((task) => {
              return task.id === taskId
                ? {
                    ...task,
                    responsibilities: task.responsibilities.map((responsibility) => ({
                      ...responsibility,
                      taskSlots: responsibility.taskSlots.map((slot) => {
                        return slot.id === slotId
                          ? {
                              ...slot,
                              task_assignment_id: isAssign ? user.id : null,
                              taskAssignment: isAssign ? { user } : null,
                            }
                          : slot;
                      }),
                    })),
                  }
                : task;
            }),
          })),
        );
        if (isAssign) {
          await TaskAssigmentService.assignUser(user.id, slotId);
        } else {
          await TaskAssigmentService.unassignUser(slotId);
        }
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, tasks })));
        notify.error('Somethin went wrong');
      }
    };
  }
}

export class Selectors {
  static filterTasks(state: AppState) {
    return state.production_workflow_additional_tasks.tasks.filter((task) => !task.id.includes('new'));
  }
}

export const reducer = stateController.getReducer();
