import { RewardsState, TaskAssignmentRewardState, TaskBonusesState } from 'pages/task/types';
import { LaunchedByModel, TaskAssignmentRewardModel, TaskBonusModel, TaskRewardsModel } from 'services/production-task.model';
import { UserShortInfo } from 'services/user.model';
import getFormattedSpentTime from 'utils/get-formatted-spent-time';

export const formatLaunchedBy = (launchedBy?: LaunchedByModel): string => {
  return (launchedBy?.first_name && launchedBy?.last_name && `${launchedBy?.first_name} ${launchedBy?.last_name}`) || null;
};

export const formatFailedBy = (failedBy?: UserShortInfo): string => {
  return (failedBy?.first_name && failedBy?.last_name && `${failedBy?.first_name} ${failedBy?.last_name}`) || '';
};

const mapBonusesToTaskBonusesState = (bonuses: TaskBonusModel[]): TaskBonusesState[] => {
  return bonuses.map((item) => ({
    id: item.id,
    name: item.name,
    value: String(item.value),
    rewardType: item.reward_type,
    valuePrev: String(item.value),
  }));
};

const mapAssignmentRewardsToTaskAssignmentRewardState = (
  assignmentRewards: TaskAssignmentRewardModel[],
): TaskAssignmentRewardState[] => {
  return assignmentRewards.map((assignmentReward) => ({
    bonus: assignmentReward.bonus,
    reward: assignmentReward.reward,
    total: assignmentReward.total_sum,
    userId: assignmentReward.user.id,
    lastName: assignmentReward.user.last_name,
    firstName: assignmentReward.user.first_name,
    taskAssignmentId: assignmentReward.task_assignment_id,
    avatarImageUrl: assignmentReward.user.avatar_image_url,
    correctionSum: String(assignmentReward.correction_sum),
    timeSpentPercent: assignmentReward.time_spent_in_percent,
    correctionSumPrev: String(assignmentReward.correction_sum),
    timeSpent: getFormattedSpentTime(assignmentReward.time_spent, true),
    is_in_current_reporting_period: assignmentReward.is_in_current_reporting_period,
  }));
};

export const mapRewardsToRewardsState = (rewards: TaskRewardsModel): RewardsState => {
  return {
    totalBonus: String(rewards.total_bonus),
    totalReward: String(rewards.total_reward),
    basicReward: String(rewards.basic_reward),
    basicRewardPrev: String(rewards.basic_reward),
    bonuses: mapBonusesToTaskBonusesState(rewards.bonuses),
    assignmentRewards: mapAssignmentRewardsToTaskAssignmentRewardState(rewards.assignment_rewards),
  };
};
