import { TabRightIcon } from 'icons/tab-right';
import { bindTrigger } from 'material-ui-popup-state';
import { ReactNode, useRef, useState, FC } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import s from './dropdown-permission-selector.module.scss';

type OptionType = {
  name: string;
  id: string | any;
  icon?: ReactNode;
  description?: string | ReactNode;
  onClick?: () => void;
  [key: string]: any;
};
type OwnProps = {
  value: OptionType;
  icon?: ReactNode;
  isShowSelected?: boolean;
  options: Array<OptionType>;
  containerClassName?: string;
  isFullDropdownWidth?: boolean;
  onChange: (value: OptionType) => void;
};

const DropdownPermissionSelector: FC<OwnProps> = ({
  icon,
  value,
  options,
  containerClassName,
  isFullDropdownWidth,
  isShowSelected = true,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const popupState = usePopupState({ variant: 'popper', popupId: `filter-selector` });
  popupState.close = () => setIsOpen(false);

  const onSelectValue = (option: OptionType) => {
    onChange(option);
    setIsOpen(false);
  };

  const filteredOptions = isShowSelected ? options : options.filter((i) => i.id !== value.id);

  return (
    <div ref={rootRef} {...bindTrigger(popupState)}>
      <div className={`${s.container} ${containerClassName}`} onClick={() => setIsOpen(true)}>
        {icon && <div className={s.icon_container}>{icon}</div>}
        {value && <div className={s.value_indicator}>{options.find((i) => i.id === value.id).name}</div>}
        <TabRightIcon className={`${s.chevron_icon} ${isOpen ? s.open : ''}`} />
      </div>
      <Popper
        transition
        open={isOpen}
        disablePortal
        role={undefined}
        placement="bottom-end"
        className={s.menu_wrapper}
        anchorEl={rootRef.current}
        style={{ width: isFullDropdownWidth && rootRef.current ? rootRef.current.clientWidth : 350 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} timeout={100} className={s.grow} data-bottomstart={placement === 'bottom-start'}>
            <Paper className={s.menu_container}>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <div className={s.inner_container}>
                  <div className={s.options}>
                    {filteredOptions.map((option) => (
                      <div
                        key={option.id}
                        onClick={() => onSelectValue(option)}
                        className={`${s.option_container} ${option.id === value.id ? s.disabled : ''}`}
                      >
                        {option.icon && option.icon}
                        <div className={s.right_col}>
                          <b>{option.name}</b>
                          {option.description && <p className={s.description}>{option.description}</p>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DropdownPermissionSelector;
