import { combineReducers } from 'redux';
import { reducer as editNoteReducer } from 'pages/production/controllers/edit-note.controller';
import { reducer as editOrderReducer } from 'pages/production/controllers/edit-order-modal.controller';
import { reducer as editClientReducer } from 'pages/production/controllers/edit-client-modal.controller';
import { reducer as editVariantReducer } from 'pages/production/controllers/edit-variant-modal.controller';
import { manageTaskPriorityModalReducer } from 'pages/production/controllers/manage-task-priority.controller';
import { deleteProductionsModalReducer } from 'pages/production/controllers/delete-productions-modal.controller';
import { reducer as stopProductionModalReducer } from 'pages/production/controllers/stop-production-modal.controller';
import { ManageComponentsReducer } from 'pages/production/controllers/manage-components-modal.controller/manage-components-modal.controller';
import { reducer as componentsHistoryModalReducer } from 'pages/production/controllers/components-history-modal.controller';
import { NewComponentModalReducer } from 'pages/production/manage-components-modal/components/new-component-modal/new-component-modal.controller';
import { reducer as productionReducer } from './controllers/production-list-controller/production-list.controller';
import { reducer as productionNewModalReducer } from './controllers/production-new-modal-controller/production-new-modal.controller';
import { reducer as productsLaunchModalReducer } from './controllers/products-launch-modal-controller/products-launch-modal.controller';
import { Reducer as filtersReducer } from './controllers/production-filters-controller/production-filters.controller';

export const reducer = combineReducers({
  filters: filtersReducer,
  editNote: editNoteReducer,
  editOrderModal: editOrderReducer,
  productionList: productionReducer,
  editClientModal: editClientReducer,
  editVariantModal: editVariantReducer,
  newComponentModal: NewComponentModalReducer,
  productionNewModal: productionNewModalReducer,
  manageComponentsModal: ManageComponentsReducer,
  productsLaunchModal: productsLaunchModalReducer,
  stopProductionModal: stopProductionModalReducer,
  componentsHistoryModal: componentsHistoryModalReducer,
  deleteProductionsModal: deleteProductionsModalReducer,
  manageTaskPriorityModal: manageTaskPriorityModalReducer,
});
