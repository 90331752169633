import { User } from 'services/user.model';
import { AppState } from 'redux/store';
import { TaskTemplateResponsibilityT } from 'services/workflow-task-template.model';
import { DepartmentService } from 'services/department.service';
import { TaskResponsibilityModel } from 'services/production-task.model';
import { PositionTypesService } from 'services/position-types.service';
import { StateController } from 'state-controller';
import { IdName, MetaResponse } from 'types/common-types';
import { UserService } from 'services/user.service';
import { validators } from 'utils/validator';
import { notify } from 'notifications';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';

export type CountChangeModeType = 'increment' | 'decrement' | 'set';
export type SearchType = 'departments' | 'positions' | 'users';

export type SelectedDepartmentType = IdName & {
  type: string;
  users_count?: number;
  avatar_image_url?: string;
};
export type SelectedPositionType = IdName & {
  department?: IdName;
  type: string;
  users_count?: number;
  avatar_image_url?: string;
};
export type SelectedWorkerType = IdName & {
  department?: IdName;
  position?: IdName;
  avatar_image_url?: string;
  type: string;
  first_name: string;
  last_name: string;
};
export type UserWithName = User & {
  name: string;
  type: string;
  users_count?: number;
};

type SearchItemType = {
  id: string;
  name: string;
  position?: IdName | null;
  department?: IdName | null;
  type: string;
};
export type SearchUserItemType = SearchItemType & { avatar_image_url: string };
export type SearchPositionItemType = SearchItemType;
export type SearchDepartmentItemType = SearchItemType & { path: Array<IdName> };

export enum SelectedItemEnum {
  DEPARTMENT = 'departments',
  USER = 'users',
  POSITION = 'positions',
}

export type GeneralData = {
  name: string;
  namesArray?: string[];
  workersCount: number;
  type: AssignmentType;
};

export type AccessData = {
  selectedWorkers: Array<SelectedWorkerType>;
  selectedDepartments: Array<SelectedDepartmentType>;
  selectedPositions: Array<SelectedPositionType>;
};

export type FieldsValidation = {
  name: string;
  uniqueName: string;
  workersCount: string;
  validatedName: string;
  numberOfPerformers: string;
};

export type OpenUpdateModal = {
  currentId: string;
  general: GeneralData;
  access: AccessData;
  names: string[];
  isNumberOfPerformersHidden?: boolean;
};

export type ResponsibilityModalState = {
  currentId: string;
  isOpen: boolean;
  isUpdate: boolean;
  general: GeneralData;
  access: AccessData;
  fieldsValidation: FieldsValidation;
  allUsersCount: number;
  listOfCandidates: UserWithName[];
  searchMode: SearchType | null;
  searchString: string;
  searchedList: Array<SearchUserItemType | SearchDepartmentItemType | SearchPositionItemType>;
  searchedMeta: MetaResponse | null;
  isSearching: boolean;
  isGeneralConfigChanged: boolean;
  isSearchModeChanged: boolean;
  isAllConfigChanged: boolean;
  isFinalStep: boolean;
  isNumberOfPerformersHidden: boolean;
};

const defaultState: ResponsibilityModalState = {
  currentId: '',
  isOpen: false,
  isUpdate: false,
  general: {
    name: '',
    namesArray: [],
    workersCount: 1,
    type: AssignmentType.Manual,
  },
  access: {
    selectedWorkers: [],
    selectedDepartments: [],
    selectedPositions: [],
  },
  fieldsValidation: {
    name: '',
    uniqueName: '',
    workersCount: '',
    validatedName: '',
    numberOfPerformers: '',
  },
  allUsersCount: 0,
  listOfCandidates: [],
  searchMode: null,
  searchString: '',
  searchedList: [],
  searchedMeta: null,
  isSearching: true,
  isGeneralConfigChanged: false,
  isSearchModeChanged: false,
  isAllConfigChanged: false,
  isFinalStep: false,
  isNumberOfPerformersHidden: false,
};

const stateController = new StateController<ResponsibilityModalState>(
  'WORKFLOW_TASK_TEMPLATE_RESPONSIBILITY_MODAL',
  defaultState,
);

export class Actions {
  public static openModal(items: TaskTemplateResponsibilityT[] | TaskResponsibilityModel[]) {
    return async (dispatch, getState: () => AppState) => {
      const itemsCount = items.length;
      const names = items.map((responsibility) => responsibility.name);

      const allUsers = (await UserService.getAllUsers({ skip: 0, take: 9000 })).data;

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isOpen: true,
          general: {
            ...prevState.general,
            workersCount: 1,
            name: `Responsibility ${itemsCount + 1}`,
          },
          allUsersCount: allUsers.length,
        })),
      );

      const currentName = getState().workflow_task_template_responsibility_modal.general.name;
      const namesArray = names.filter((i) => i !== currentName);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          general: {
            ...prev.general,
            namesArray,
          },
        })),
      );
    };
  }

  public static openUpdateModal({ currentId, general, access, names, isNumberOfPerformersHidden }: OpenUpdateModal) {
    return async (dispatch) => {
      dispatch(
        stateController.setState(() => ({
          ...defaultState,
        })),
      );
      const namesArray = names.filter((i) => i !== general.name);
      const allUsers = (await UserService.getAllUsers({ skip: 0, take: 9000 })).data;
      const allUsersWithName: UserWithName[] = allUsers.map((item) => ({
        ...item,
        name: `${item.first_name} ${item.last_name}`,
        type: '',
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
          isUpdate: true,
          currentId,
          general: {
            name: general.name,
            namesArray,
            workersCount: general.workersCount,
            type: general.type,
          },
          access,
          listOfCandidates: allUsersWithName,
          allUsersCount: allUsersWithName.length,
          ...(isNumberOfPerformersHidden ? { isNumberOfPerformersHidden } : {}),
        })),
      );
    };
  }

  public static getCandidates() {
    return async (dispatch, getState: () => AppState) => {
      const subState = getState().workflow_task_template_responsibility_modal.access;

      const userIds = subState.selectedWorkers.map((i) => i.id).join(',') || undefined;
      const positionIds = subState.selectedPositions.map((i) => i.id).join(',') || undefined;
      const departmentIds = subState.selectedDepartments.map((i) => i.id).join(',') || undefined;

      const response = (
        await UserService.getAllUsers({
          department_id: departmentIds,
          position_type_id: positionIds,
          user_id: userIds,
          skip: 0,
          take: 9000,
        })
      ).data;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          listOfCandidates: response.map((item) => ({
            ...item,
            name: `${item.first_name} ${item.last_name}`,
          })),
        })),
      );

      dispatch(Actions.fieldValidation());
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState(() => ({
          ...defaultState,
        })),
      );
    };
  }

  public static changeResponsibilityName(name: string) {
    return async (dispatch) => {
      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            name,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static changeWorkersCount(mode?: CountChangeModeType, count?: number) {
    return async (dispatch, getState: () => AppState) => {
      const { general } = getState().workflow_task_template_responsibility_modal;
      let result = count;

      if (mode === 'decrement') {
        result = general.workersCount - 1;
      }
      if (mode === 'increment') {
        result = general.workersCount + 1;
      }

      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            workersCount: result < 1 ? 1 : result,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static changeResponsibilityType(type: AssignmentType) {
    return async (dispatch) => {
      await dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          general: {
            ...prevState.general,
            type,
          },
        })),
      );
      dispatch(Actions.fieldValidation());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static setSearchType(type: SearchType) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          searchMode: type,
          searchString: '',
          searchedList: [],
          searchedMeta: null,
          isSearching: true,
        })),
      );
      dispatch(Actions.isConfigChanged());
    };
  }

  public static searchByValue(value?: string) {
    return async (dispatch, getState: () => AppState) => {
      let searchedList = [];
      let searchedMeta = null;
      const { searchMode, access, searchedList: alreadySearchedList } = getState().workflow_task_template_responsibility_modal;

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isSearching: true,
            searchString: value,
          })),
        );

        if (searchMode === 'departments') {
          const { data, meta } = await DepartmentService.getAllDepartamentsByPage({
            search: value,
            skip: 0,
            take: 45000,
            user_count: true,
          });
          let filteredList = data;
          access.selectedDepartments.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });
          const mappedList = filteredList.map((item) => ({
            id: item.id,
            name: item.name,
            path: item.path,
            type: searchMode,
            users_count: item.users_count,
          }));
          searchedList = meta.currentPage > 1 ? [...alreadySearchedList, ...mappedList] : mappedList;
          searchedMeta = meta;
        }

        if (searchMode === 'positions') {
          const departmentIds =
            getState()
              .workflow_task_template_responsibility_modal.access.selectedDepartments.map((i) => i.id)
              .join(',') || undefined;

          let filteredList = await PositionTypesService.getPositionTypes(value, true, departmentIds);

          access.selectedPositions.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });

          searchedList = filteredList.map((item) => ({
            id: item.id,
            name: item.name,
            department: { name: item.description },
            type: searchMode,
            users_count: item.users_count,
          }));
        }

        if (searchMode === 'users') {
          const subState = getState().workflow_task_template_responsibility_modal.access;
          const positionIds = subState.selectedPositions.map((i) => i.id).join(',') || undefined;
          const departmentIds = subState.selectedDepartments.map((i) => i.id).join(',') || undefined;

          let filteredList = (
            await UserService.getAllUsers({
              search: value,
              department_id: departmentIds,
              position_type_id: positionIds,
              skip: 0,
              take: 9000,
            })
          ).data;

          access.selectedWorkers.forEach((item) => {
            filteredList = filteredList.filter((filtered) => item.id !== filtered.id);
          });

          searchedList = filteredList.map((item) => ({
            id: item.id,
            name: `${item.first_name} ${item.last_name}`,
            department: { name: item.departments.map((dep) => dep.name).join(', ') || 'відсутній' },
            position: { name: item.positions.map((pos) => pos.name).join(', ') || 'відсутня' },
            type: searchMode,
            avatar_image_url: item.avatar_image_url,
            first_name: item.first_name || '',
            last_name: item.last_name || '',
          }));
        }
      } finally {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isSearching: false,
            searchString: value,
            searchedList,
            searchedMeta,
          })),
        );
        dispatch(Actions.isConfigChanged());
      }
    };
  }

  public static clearOptions() {
    return async (dispatch, getState: () => AppState) => {
      const { isFinalStep } = getState().workflow_task_template_responsibility_modal;
      dispatch(
        stateController.setState((prev) => ({
          ...defaultState,
          isOpen: !isFinalStep,
          isUpdate: prev.isUpdate,
        })),
      );
    };
  }

  public static addToSelectedList(data: SearchUserItemType | SearchPositionItemType | SearchDepartmentItemType) {
    return async (dispatch, getState: () => AppState) => {
      const { searchMode, searchedList, access } = getState().workflow_task_template_responsibility_modal;
      let newAccess;

      if (searchMode === 'departments') {
        newAccess = {
          ...access,
          selectedDepartments: [...access.selectedDepartments, data],
        };
      }

      if (searchMode === 'positions') {
        newAccess = {
          ...access,
          selectedPositions: [...access.selectedPositions, data],
        };
      }

      if (searchMode === 'users') {
        newAccess = {
          ...access,
          selectedWorkers: [...access.selectedWorkers, data],
        };
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          access: newAccess,
          searchedList: searchedList.filter((item) => item.id !== data.id),
        })),
      );
      dispatch(Actions.isConfigChanged());
    };
  }

  public static deleteFromSelectedList(data: SelectedDepartmentType | SelectedPositionType | SelectedWorkerType) {
    return async (dispatch, getState: () => AppState) => {
      const { access, searchedList, searchMode } = getState().workflow_task_template_responsibility_modal;
      const { id, type } = data;
      let newAccess;
      let msgName: string;

      if (type === 'departments') {
        msgName = 'Department';
        newAccess = {
          ...access,
          selectedDepartments: access.selectedDepartments.filter((item) => item.id !== id),
        };
      }

      if (type === 'positions') {
        msgName = 'Position';
        newAccess = {
          ...access,
          selectedPositions: access.selectedPositions.filter((item) => item.id !== id),
        };
      }

      if (type === 'users') {
        msgName = 'User';
        newAccess = {
          ...access,
          selectedWorkers: access.selectedWorkers.filter((item) => item.id !== id),
        };
      }

      notify.success(`${msgName} - ${data.name} has been successfully deleted!`);

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          access: newAccess,
          searchedList: data.type === searchMode ? [...searchedList, data] : [...searchedList],
        })),
      );
      dispatch(Actions.searchByValue());
      dispatch(Actions.isConfigChanged());
    };
  }

  public static fieldValidation() {
    return (dispatch, getState: () => AppState) => {
      const { general } = getState().workflow_task_template_responsibility_modal;
      const { namesArray } = getState().workflow_task_template_responsibility_modal.general;
      const { listOfCandidates } = getState().workflow_task_template_responsibility_modal;
      const { workersCount } = getState().workflow_task_template_responsibility_modal.general;

      const [, nameErrorMessage] = validators.isNotEmpty(general.name, `Значення не повинно бути пустим`);
      const [, workerErrorMessage] = validators.isGreaterThanZero(general.workersCount, `Значення не повинно бути меншим за 1`);
      const [, uniqueNameErrorMessage] = validators.isUniqueString(
        { value: general.name, valueArray: namesArray },
        'Task responsibility title is not unique',
      );
      const validationNameErrorMessage =
        general.name === general.name.trim()
          ? ''
          : 'The value must be without leading or trailing spaces, and must not consist only of spaces';
      const numberOfPerformersErrorMessage =
        workersCount > listOfCandidates?.length ? 'Insufficient number of performers added' : null;

      const fieldsValidated = {
        name: nameErrorMessage,
        uniqueName: uniqueNameErrorMessage,
        workersCount: workerErrorMessage,
        numberOfPerformers: numberOfPerformersErrorMessage,
        validatedName: validationNameErrorMessage,
      };

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          fieldsValidation: fieldsValidated,
        })),
      );
    };
  }

  public static isConfigChanged() {
    return (dispatch, getState: () => AppState) => {
      const { general, searchMode } = getState().workflow_task_template_responsibility_modal;
      const isGeneralConfigChanged = !!general.name || !!general.workersCount;
      const isSearchModeChanged = searchMode !== null;
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isGeneralConfigChanged,
          isSearchModeChanged,
          isAllConfigChanged: isGeneralConfigChanged || isSearchModeChanged,
        })),
      );
    };
  }
}

export class Selectors {
  public static countInSelectedDepartments(state: AppState) {
    const { selectedDepartments } = state.workflow_task_template_responsibility_modal.access;
    const countOfUsersArray = selectedDepartments?.map((i) => i.users_count);
    return (countOfUsersArray?.length > 0 && countOfUsersArray.reduce((acc, cur) => acc + cur)) || 0;
  }
}

export const reducer = stateController.getReducer();
