import { useSelector } from 'react-redux';
import { AccessLevel } from 'services/permission.model';
import { AppState } from '../redux/store';

const useIsPermitted = (permission: string, accessLevels: AccessLevel[]) => {
  const user = useSelector((state: AppState) => state.auth.user);
  return accessLevels.some((level) => level === user?.user_permissions?.[permission]);
};

export default useIsPermitted;
