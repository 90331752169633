import { FC, SVGProps } from 'react';

export const LogoWithoutTitleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.8328 0.358398H20.3317L13.014 8.79071H21.3795C25.8656 8.79071 25.7937 12.048 24.476 13.6073L15.0545 24.6013H5.55561L15.6656 12.9514C16.7205 11.7358 15.6099 9.84474 13.9991 9.84474C12.6363 9.84474 11.5865 10.4356 10.694 11.4642L8.83409 13.6073C7.42218 15.2343 5.90965 16.169 3.21673 16.169C0.828026 16.169 -0.758356 13.7556 0.806081 11.9528L10.8328 0.358398Z"
      fill="#0851FE"
    />
  </svg>
);
