import React, { SVGProps } from 'react';

const PenIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.44568 2.39424C9.95709 1.71316 10.8751 1.61408 11.4986 2.17113C11.5335 2.20122 12.6413 3.16045 12.6413 3.16045C13.3267 3.62135 13.5389 4.60186 13.1151 5.34972C13.0927 5.39009 6.83066 14.1142 6.83066 14.1142C6.6224 14.4033 6.30541 14.5751 5.96733 14.5787L3.56979 14.6118L3.02873 12.0651C2.95294 11.7062 3.02873 11.3311 3.23698 11.0405L9.44568 2.39424Z"
        stroke={props.color || '#141414'}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.84766 4.59375L11.3081 6.69855"
        stroke={props.color || '#141414'}
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PenIcon;
