import { baseAxiosInstance } from 'axios-config';
import {
  CreateWorkflowTaskTemplateTagRelationData,
  ManageWorkflowTaskTemplateTagsData,
  UpdateWorkflowTaskTemplate,
  WorkflowTaskTemplate,
  WorkflowTaskTemplateTagRelation,
} from 'services/workflow-task-template.model';

export class WorkflowTaskTemplateService {
  public static async getWorkflowTaskTemplateInfoById(taskTemplateId: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTaskTemplate>(`/workflow-task-templates/info/${taskTemplateId}`);
    return data;
  }

  public static async updateWorkflowTaskTemplate(id: string, productData: UpdateWorkflowTaskTemplate) {
    const { data } = await baseAxiosInstance.put<WorkflowTaskTemplate>(`/workflow-task-templates/update/${id}`, productData);
    return data;
  }

  public static async createWorkflowTaskTemplateTagRelation(body: CreateWorkflowTaskTemplateTagRelationData) {
    const { data } = await baseAxiosInstance.post<
      Pick<WorkflowTaskTemplateTagRelation, 'id' | 'workflow_task_template_id' | 'task_tag_id'>
    >('/workflow-task-template-tag-relation/create', body);

    return data;
  }

  public static async deleteWorkflowTaskTemplateTagRelation(id: string) {
    const { data } = await baseAxiosInstance.delete<
      Pick<WorkflowTaskTemplateTagRelation, 'id' | 'workflow_task_template_id' | 'task_tag_id'>
    >(`/workflow-task-template-tag-relation/${id}`);

    return data;
  }

  public static async manageWorkflowTaskTemplateTags(body: ManageWorkflowTaskTemplateTagsData) {
    const { data } = await baseAxiosInstance.post(`/workflow-task-template-tag-relation/manage`, body);

    return data;
  }
}
