import { FC, SVGProps } from 'react';

export const OrdersIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.8538 20.8577H4.13951C3.76063 20.8577 3.39727 20.7072 3.12936 20.4393C2.86145 20.1714 2.71094 19.808 2.71094 19.4291V5.14342C2.71094 4.76453 2.86145 4.40117 3.12936 4.13326C3.39727 3.86535 3.76063 3.71484 4.13951 3.71484H19.8538C20.2327 3.71484 20.596 3.86535 20.8639 4.13326C21.1319 4.40117 21.2824 4.76453 21.2824 5.14342V12.2863"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.71094 8H21.2824" stroke="#878B92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.5714 20.1429C17.5439 20.1429 19.1429 18.5439 19.1429 16.5714C19.1429 14.599 17.5439 13 15.5714 13C13.599 13 12 14.599 12 16.5714C12 18.5439 13.599 20.1429 15.5714 20.1429Z"
      stroke="#878B92"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19.1016 18.0996L22.2873 21.2853" stroke="#878B92" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
