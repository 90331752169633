/* eslint-disable no-restricted-imports */
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/lab';
import { notify } from 'notifications';
import { AppState } from 'redux/store';
import { debounce } from 'utils/debounce';
import { StateController } from 'state-controller';
import { getSkipAndTake } from 'utils/get-skip-and-take';
import { ProductsService } from 'services/products.service';
import { IdName, SortOrderOption } from 'types/common-types';
import { ProductTagsService } from 'services/product-tags.service';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductVendorService } from 'services/product-vendor.service';
import { ProductCategoriesService } from 'services/product-categories.service';
import { ProductOptionValuesService } from 'services/product-option-values.service';
import { Actions as MultiselectActions } from 'pages/products/multiselect.controller';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { UpdateProductMeta, ProductData, ProductDataPublishedProducts } from 'services/products.model';
import { FilterCriteriaEnum, SingleOptionEnum, SortOptionsEnum } from './filter-enums';

export const ITEMS_PER_PAGE = 50;

type FilterCriteriasUnion = `${FilterCriteriaEnum}`;
type FilterDataType = {
  disabled?: boolean;
  isOptionsLoading?: boolean;
  additionalOptions?: IdName[];
  options?: Array<IdName | { [key: string]: any }>;
  value?: IdName | IdName[] | Dayjs | DateRange<Dayjs>;
};
export type SortState = {
  sort_order: { value: SortOrderOption };
  sort_by: {
    value: {
      id: SortOptionsEnum;
      name: string;
    };
    options: IdName[];
  };
};
export type FilterSelectorState = {
  value: IdName[];
  options: IdName[];
};
export type PaginationState = {
  total: number;
  lastPage: number;
  currentPage: number;
};
export type FiltersType = { [key in FilterCriteriasUnion]: FilterDataType } & { keyword: string };
export type OnChangeFilterParam = Partial<{ [key in FilterCriteriasUnion]: FilterDataType['value'] }>;

type ProductSearchState = {
  sort: SortState;
  filters: FiltersType;
  isFiltersLoaded: boolean;
  renameModeItemId: string;
  isProductsFetching: boolean;
  pagination: PaginationState;
  filterSelector: FilterSelectorState;
  productItems: ProductDataPublishedProducts[];
};

const defaultState: ProductSearchState = {
  productItems: [],
  isFiltersLoaded: false,
  isProductsFetching: false,
  renameModeItemId: '',
  sort: {
    sort_by: {
      value: {
        id: SortOptionsEnum.Name,
        name: 'Product name',
      },
      options: [
        { id: SortOptionsEnum.Name, name: 'Product name' },
        { id: SortOptionsEnum.Type, name: 'Product type' },
        { id: SortOptionsEnum.Date, name: 'Publish date' },
      ],
    },
    sort_order: {
      value: SortOrderOption.Descending,
    },
  },
  filterSelector: {
    value: [
      { id: FilterCriteriaEnum.Sku, name: 'SKU' },
      { id: FilterCriteriaEnum.Tags, name: 'Tags' },
      { id: FilterCriteriaEnum.Options, name: 'Options' },
      { id: FilterCriteriaEnum.Category, name: 'Category' },
    ],
    options: [
      { id: FilterCriteriaEnum.Tags, name: 'Tags' },
      { id: FilterCriteriaEnum.Category, name: 'Category' },
      { id: FilterCriteriaEnum.Sku, name: 'SKU' },
      { id: FilterCriteriaEnum.Options, name: 'Options' },
      { id: FilterCriteriaEnum.PublishDate, name: 'Publish date' },
      { id: FilterCriteriaEnum.LastUpdate, name: 'Last update' },
      { id: FilterCriteriaEnum.Vendor, name: 'Vendor' },
      { id: FilterCriteriaEnum.ProductName, name: 'Product name' },
      { id: FilterCriteriaEnum.ProductionStatus, name: 'Production status' },
      { id: FilterCriteriaEnum.ContainsDraft, name: 'Contains draft' },
    ],
  },
  filters: {
    keyword: '',
    tags: {
      value: [],
      options: [],
    },
    vendor: {
      value: [],
      options: [],
      additionalOptions: [],
    },
    options: {
      value: [],
      options: [],
    },
    category: {
      value: [],
      options: [],
      additionalOptions: [],
    },
    product_name: {
      value: [],
      options: [],
    },
    sku: {
      value: [],
      options: [],
      isOptionsLoading: false,
    },
    contains_draft: {
      value: { id: SingleOptionEnum.All, name: 'All' },
      options: [
        { id: SingleOptionEnum.All, name: 'All' },
        { id: SingleOptionEnum.Yes, name: 'Yes' },
        { id: SingleOptionEnum.No, name: 'No' },
      ],
    },
    last_update: {
      value: [null, null],
    },
    publish_date: {
      value: [null, null],
    },
    production_status: {
      value: { id: SingleOptionEnum.All, name: 'All' },
      options: [
        { id: SingleOptionEnum.All, name: 'All' },
        { id: SingleOptionEnum.Yes, name: 'Yes' },
        { id: SingleOptionEnum.No, name: 'No' },
      ],
    },
  },
  pagination: {
    total: 0,
    lastPage: 0,
    currentPage: 1,
  },
};

const stateController = new StateController<ProductSearchState>('PRODUCT_SEARCH', defaultState);

const returnIds = (values: IdName[]) => {
  return values.map((value) => value.id);
};

export class Actions {
  public static init() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isProductsFetching: true }));
      await dispatch(Actions.loadAndSetDefaultFiltersState());
      dispatch(Actions.getProductsByFilter());
    };
  }

  public static disposeState() {
    return async (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static getProductsByFilter(spinner: boolean = true) {
    return async (dispatch, getState: () => AppState) => {
      try {
        if (spinner) dispatch(stateController.setState({ isProductsFetching: true }));

        const { filters, pagination, sort } = getState().productSearch;

        const getSingleOption = (status: string) => {
          switch (status) {
            case SingleOptionEnum.Yes:
              return true;
            case SingleOptionEnum.No:
              return false;
            case SingleOptionEnum.All:
              return undefined;
            default:
              return undefined;
          }
        };

        const containsDraftId = filters.production_status.value as IdName;
        const isAvailableId = filters.production_status.value as IdName;

        const { skip, take } = getSkipAndTake(ITEMS_PER_PAGE, pagination.currentPage);

        const filterOptions = {
          skip,
          take,
          name: filters.keyword,
          order_by: sort.sort_by.value.id,
          order_direction: sort.sort_order.value,
          is_available: getSingleOption(isAvailableId.id),
          contains_draft: getSingleOption(containsDraftId.id),
          sku: returnIds(filters.sku.value as IdName[]).join(','),
          parameters: returnIds(filters.options.value as IdName[]).join(','),
          product_tag_id: returnIds(filters.tags.value as IdName[]).join(','),
          product_vendor_id: returnIds(filters.vendor.value as IdName[]).join(','),
          product_name: returnIds(filters.product_name.value as IdName[]).join(','),
          product_category_id: returnIds(filters.category.value as IdName[]).join(','),
          publish_date_end: filters.publish_date.value[1] && decodeURIComponent(filters.publish_date.value[1]),
          publish_date_start: filters.publish_date.value[0] && decodeURIComponent(filters.publish_date.value[0]),
          last_update_date_end: filters.last_update.value[1] && decodeURIComponent(filters.last_update.value[1]),
          last_update_date_start: filters.last_update.value[0] && decodeURIComponent(filters.last_update.value[0]),
        };

        const data = await ProductsService.getProductsByFilter(filterOptions);

        dispatch(stateController.setState({ productItems: data.data }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              total: data.meta.total,
              lastPage: data.meta.lastPage,
            },
          })),
        );
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        if (spinner) dispatch(stateController.setState({ isProductsFetching: false }));
      }
    };
  }

  public static loadAndSetDefaultFiltersState() {
    return async (dispatch) => {
      try {
        const [vendors, options, tags, products, categories] = await Promise.all([
          await ProductVendorService.getAll('', 0, 9000),
          await ProductOptionValuesService.getAllUniqueNames('', 0, 9000),
          await ProductTagsService.getAllTags(),
          await ProductsService.getProductsByKeyword(''),
          await ProductCategoriesService.getProductCategories(),
        ]);

        const categoriesMapped = categories.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        const vendorsMapped = vendors.data.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        const productUniqueNames = products
          .map((i) => i.name)
          .filter((value, index, array) => array.indexOf(value) === index)
          .sort();
        const productsMapped = productUniqueNames.map((item) => ({
          id: item,
          name: item,
        }));

        const filters = {
          category: {
            value: [],
            options: categoriesMapped,
            additionalOptions: [{ id: 'null', name: 'No category' }],
          },
          product_name: {
            value: [],
            options: productsMapped,
          },
          tags: {
            value: [],
            options: tags,
          },
          vendor: {
            value: [],
            options: vendorsMapped,
            additionalOptions: [{ id: 'null', name: 'No vendor' }],
          },
          options: {
            value: [],
            options: options.data,
          },
        };

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              tags: filters.tags,
              vendor: filters.vendor,
              options: filters.options,
              category: filters.category,
              product_name: filters.product_name,
            },
          })),
        );
      } finally {
        dispatch(stateController.setState({ isFiltersLoaded: true }));
      }
    };
  }

  public static changeFilterOptions(filterOptions: OnChangeFilterParam) {
    return async (dispatch) => {
      let filterOptionsFormatted = filterOptions;
      if ('sku' in filterOptions) {
        filterOptionsFormatted = {
          sku: (filterOptions.sku as any[]).map((item) => ({
            id: item.id,
            name: item.sku,
          })),
        };
      }
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: Object.keys(filterOptionsFormatted).reduce(
            (acc, item) => ({
              ...acc,
              [item]: { ...prevState.filters[item], options: filterOptionsFormatted[item] },
            }),
            { ...prevState.filters },
          ),
        })),
      );
    };
  }

  public static onFiltersChange(newValue: OnChangeFilterParam) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: {
            ...Object.keys(newValue).reduce(
              (acc, item) => ({
                ...acc,
                [item]: { ...prevState.filters[item], value: newValue[item] },
              }),
              { ...prevState.filters },
            ),
          },
        })),
      );

      debounce(async () => {
        dispatch(Actions.getProductsByFilter());
      }, 1000);
    };
  }

  public static onChangeSortMode(value: Partial<ProductSearchState['sort']>) {
    return (dispatch, getState: () => AppState) => {
      const { sort_by, sort_order } = getState().productSearch.sort;

      if (value.sort_order?.value === sort_order.value || value.sort_by?.value === sort_by.value) return;

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          sort: {
            ...Object.keys(value).reduce(
              (acc, item) => ({
                ...acc,
                [item]: { ...prevState.sort[item], value: value[item].value },
              }),
              { ...prevState.sort },
            ),
          },
        })),
      );

      dispatch(Actions.getProductsByFilter());
    };
  }

  public static onSelectFilters(values: IdName[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filterSelector: {
            ...prev.filterSelector,
            value: values,
          },
        })),
      );
    };
  }

  public static onKeywordChange(value: string, withRequest: boolean = true) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: value,
          },
        })),
      );

      if (withRequest) {
        debounce(async () => {
          dispatch(Actions.getProductsByFilter());
        }, 1000);
      }
    };
  }

  public static clearAllFilters() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            keyword: '',
            sku: { ...prev.filters.sku, value: [] },
            tags: { ...prev.filters.tags, value: [] },
            vendor: { ...prev.filters.vendor, value: [] },
            options: { ...prev.filters.options, value: [] },
            category: { ...prev.filters.category, value: [] },
            product_name: { ...prev.filters.product_name, value: [] },
            last_update: { ...prev.filters.last_update, value: [null, null] },
            publish_date: { ...prev.filters.publish_date, value: [null, null] },
            contains_draft: { ...prev.filters.contains_draft, value: { id: SingleOptionEnum.All, name: 'All' } },
            production_status: { ...prev.filters.production_status, value: { id: SingleOptionEnum.All, name: 'All' } },
          },
        })),
      );

      dispatch(Actions.getProductsByFilter());
    };
  }

  public static onSetCurrentPage(page: number) {
    return async (dispatch, getState: () => AppState) => {
      const { currentPage } = getState().productSearch.pagination;

      if (page === currentPage) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            currentPage: page,
          },
        })),
      );
      debounce(async () => {
        dispatch(Actions.getProductsByFilter());
      }, 500);
    };
  }

  public static removeSelectedProductsFromList(selectedIds: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productItems: prev.productItems.filter((item) => !selectedIds.includes(item.id)),
        })),
      );
    };
  }

  public static removeProduct(id: string) {
    return async (dispatch) => {
      try {
        await ProductsService.deleteProduct(id);
        dispatch(Actions.removeSelectedProductsFromList([id]));

        notify.success('Successfully deleted');
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      }
    };
  }

  public static renameMode(entityId: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const products = getState().productSearch.productItems;
      const currentProductId = products.find((i) => i.product_meta.id === entityId).product_meta.id;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: currentProductId,
        })),
      );
    };
  }

  public static renameModeIsClosed() {
    return async (dispatch, getState: () => AppState) => {
      const ItemId = getState().productSearch.renameModeItemId;
      if (!ItemId) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: '',
        })),
      );
    };
  }

  public static updateProduct(id: string, data: UpdateProductMeta) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const originalProduct = getState().productSearch.productItems.find((item) => item.product_meta.id === id).product_meta;

        const newData: UpdateProductMeta = { ...originalProduct, ...data };

        const newProduct = await ProductsService.updateProductMeta(id, newData);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productItems: prev.productItems.map((item) => {
              return item.product_meta.id !== id
                ? item
                : {
                    ...item,
                    product_meta: {
                      ...newProduct,
                    },
                  };
            }),
          })),
        );

        dispatch(Actions.renameModeIsClosed());
        notify.success('Successfully renamed');
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      }
    };
  }

  public static moveSelectedItems(multiselect: boolean = true) {
    return async (dispatch, getState: () => AppState) => {
      try {
        let selectedProductIds: string[];
        const targetCategoryId = getState().products.productsModals.moveModal.data.value.id;

        if (multiselect) {
          selectedProductIds = getState().products.multiselect.selectedProductIds;
        } else {
          selectedProductIds = [getState().products.productsModals.moveModal.entityId];
        }

        await ProductsService.updateParentCategory(selectedProductIds, targetCategoryId);

        if (multiselect) {
          dispatch(MultiselectActions.deselectAll());
        }

        notify.success('Successfully moved');
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      }
    };
  }

  public static onProductIsActiveChange(productId: string, isProductActive: boolean) {
    return async (dispatch) => {
      try {
        const data = await ProductsService.changeIsActive(productId, { is_active: !isProductActive });

        if (data.message) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              // eslint-disable-next-line no-confusing-arrow
              productItems: prev.productItems.map((item) =>
                item.id === productId ? { ...item, is_active: !isProductActive } : item,
              ),
            })),
          );

          notify.success(data.message);
        }
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      }
    };
  }

  public static duplicateProduct(productId: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      try {
        const newProduct = await ProductsService.duplicateProduct(productId);
        dispatch(Actions.addProductToList(newProduct));

        notify.success('Successfully duplicated');
      } catch {
        notify.error('Something went wrong');
      }
    };
  }

  public static addProductToList(product: ProductData) {
    return async (dispatch, getState: () => AppState) => {
      const { productItems } = getState().productSearch;

      let items: ProductData[];

      if (productItems.length > 49) {
        items = productItems.slice(0, -1);
      } else {
        items = productItems;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productItems: [product, ...items],
        })),
      );
    };
  }
}

export class Selectors {
  public static filterSelectorValueIds(state: AppState) {
    return state.productSearch.filterSelector.value.reduce((acc, item) => ({ ...acc, [item.id]: item.id }), {});
  }

  public static isFiltersDirty(state: AppState) {
    const { filters } = state.productSearch;
    const containsDraftIds = filters.contains_draft.value as IdName;
    const productionStatusId = filters.production_status.value as IdName;
    const last_update = filters.last_update.value[0] !== null || filters.last_update.value[1] !== null;
    const publish_date = filters.publish_date.value[0] !== null || filters.publish_date.value[1] !== null;

    return (
      last_update ||
      publish_date ||
      filters.keyword !== '' ||
      !!(filters.sku.value as IdName[]).length ||
      !!(filters.tags.value as IdName[]).length ||
      !!(filters.vendor.value as IdName[]).length ||
      !!(filters.options.value as IdName[]).length ||
      !!(filters.category.value as IdName[]).length ||
      containsDraftIds.id !== SingleOptionEnum.All ||
      productionStatusId.id !== SingleOptionEnum.All ||
      !!(filters.product_name.value as IdName[]).length
    );
  }
}

export const reducer = stateController.getReducer();
