import { FC } from 'react';
import MoveToIcon from 'icons/move-to';
import { TrashIcon } from 'icons/trash';
import { Position2Icon } from 'icons/position-2';
import { DepartmentsIcon } from 'icons/departments';
import Checkbox from 'components/checkbox/checkbox';
import { useNavigate, useLocation } from 'react-router-dom';
import TextEditable from 'components/text-editable/text-editable';
import DropdownMenu from 'components/dropdown-menu/dropdown-menu';
import MaxLettersWarning from 'components/max-letters-warning/max-letters-warning';
import { PositionSlotByDepartmentId as PositionItem } from 'services/position-slots.model';
import { DepartmentItem as DepartmentItemType, NEW_DEPARTMENT_ID } from 'pages/departments/departments.controller';
import HorizontalShiftAnimation from 'components/wrapper-with-shift-animation/horisontal-shift-animation';
import s from './sub-department.module.scss';

type OwnProps = {
  isSelected: boolean;
  lastAddedItemId: string;
  data: DepartmentItemType;
  renameModeItemId: string;
  isMultiselectMode: boolean;
  selectElement: (id: string) => void;
  deselectElement: (id: string) => void;
  closeRenameMode: (isNew?: boolean) => void;
  onCreateClick: (name: string, parentId: string) => void;
  onMoveItem: (item: PositionItem | DepartmentItemType) => void;
  onDeleteItem: (item: PositionItem | DepartmentItemType) => void;
  renameDepartment: (departmentId: string, newName: string) => void;
};

const SubDepartment: FC<OwnProps> = ({
  data,
  isSelected,
  lastAddedItemId,
  renameModeItemId,
  isMultiselectMode,
  onMoveItem,
  onDeleteItem,
  onCreateClick,
  selectElement,
  closeRenameMode,
  deselectElement,
  renameDepartment,
}) => {
  const handleSelect = (elementId: string) => {
    if (isSelected) {
      deselectElement(elementId);
    } else {
      selectElement(elementId);
    }
  };

  const menuItems = [
    {
      onClick: () => onMoveItem(data),
      key: 'action_move_to',
      text: 'Move to',
      icon: <MoveToIcon width={16} height={16} />,
    },
    {
      onClick: () => onDeleteItem(data),
      key: 'action_delete_position',
      text: 'Delete',
      icon: <TrashIcon width={16} height={16} />,
    },
  ];

  const navigate = useNavigate();
  const handleClick = () => navigate(`/departments/${data.id}`);

  const { pathname } = useLocation();
  const match = pathname.match(/\/([a-f\d-]+)$/i);
  const parentId = match ? match[1] : null;

  const text = data.users_count === 1 ? 'position' : 'positions';
  const renameModeEnabled = renameModeItemId === data.id;
  const isNew = data.id === NEW_DEPARTMENT_ID;

  const handleCancel = () => {
    closeRenameMode(isNew);
  };

  const handleConfirm = async (name: string) => {
    if (isNew) {
      await Promise.resolve(onCreateClick(name, parentId));
    } else {
      await Promise.resolve(renameDepartment(data.id, name));
    }
  };

  return (
    <div onClick={handleClick} id={`dep-${data.id}`} className={s.container} data-highlighted={lastAddedItemId === data.id}>
      <HorizontalShiftAnimation
        animationTime={0.28}
        maxWidthWhenIsShown={31}
        isVisible={isMultiselectMode}
        componentClassName={s.checkbox_wrapper}
      >
        <div className={s.checkbox}>
          <Checkbox
            size="medium"
            checked={isSelected}
            onClick={(e) => {
              e.stopPropagation();
              handleSelect(data.id);
            }}
          />
        </div>
      </HorizontalShiftAnimation>
      <div className={s.grid}>
        <div className={s.icon}>
          <DepartmentsIcon fontSize={20} />
        </div>
        <div className={s.department_name}>
          <TextEditable
            withLoader
            maxWidth="400px"
            inputWidth="400px"
            value={data.name}
            className={s.title}
            allowedEmpty={false}
            focused={renameModeEnabled}
            textClassName={s.department_name}
            onEditEnd={(name) => handleConfirm(name)}
            onClickAway={handleCancel}
            onCancelClick={handleCancel}
            onCheckClick={() => closeRenameMode(isNew)}
            placeholder="Введіть назву департаменту"
            placeholderClassName={s.placeholder_name}
            textValidationTitle={<MaxLettersWarning />}
          />
        </div>
        <div className={s.positions_count}>
          <div className={`${s.icon} ${s.margin_right}`}>
            <Position2Icon />
          </div>
          <strong>{data.users_count}</strong>
          <span>{text}</span>
        </div>
        <div className={s.actions} data-hidden={isMultiselectMode}>
          <DropdownMenu id="user-item-more-button" items={menuItems} />
        </div>
      </div>
    </div>
  );
};

export default SubDepartment;
