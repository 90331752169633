import { ProductionDetails } from '../../../../services/production-workflow.model';

export const productionDetails: ProductionDetails = {
  id: '',
  production_key: '',
  product: {
    sku: '',
    name: '',
    vendor: '',
    barcode: '',
    variant: '',
    variant_sku: '',
    configuration: '',
    comment: '',
    product_variant_id: '',
    product_configuration_id: '',
  },
  hierarchy: {
    root_product_name: '',
    root_production_key: '',
    parent_production_key: '',
  },
  order: {
    id: '',
    order_key: '',
    to_stock: false,
    is_deleted: false,
    product_category: '',
    comment: '',
    external_order_id: '',
    external_order_number: '',
    client: { id: '', name: '' },
    marketplace_order_number: '',
    primary_client: { id: '', name: '' },
  },
  time: {
    time_spent: 0,
    started_at: '',
    created_at: '',
    deadline_at: '',
    estimated_time: 0,
    estimated_time_with_components_tasks: 0,
  },
  organization: {
    created_by: {
      avatar_image_url: '',
      first_name: '',
      id: '',
      last_name: '',
    },
    performers: [],
    responsible_departments: [],
    involved_departments: [],
  },
  tasks: {
    tasksCount: 0,
    componentsCount: 0,
    tasksWithComponentsTasksCount: 0,
  },
  cost: {
    estimated_cost: 0,
    estimated_cost_without_nested_components: 0,
  },
  notes: [],
  ordersHistory: [],
  clientHistory: [],
  deadlineHistory: [],
  variantsHistory: [],
  externalOrderNumberHistory: [],
  marketplaceOrderNumberHistory: [],
};
