import useIsPermitted from 'hooks/use-is-permitted';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppState } from 'redux/store';
import { Paths, isNonAuthorizedPath } from 'routes/paths';
import { AccessLevel, Permission } from 'services/permission.model';

export const useCompanyRoutesRedirect = () => {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: AppState) => state.auth?.user?.user_permissions);

  useEffect(() => {
    setTimeout(() => {
      if (isNonAuthorizedPath()) {
        navigate(Paths.Home);
      }
    }, 100);
  }, []);

  const isAnalyticsPagePermitted = useIsPermitted(Permission.webAnalyticsView, [AccessLevel.access]);
  useEffect(() => {
    if (!userPermissions) return;

    // redirect from analytics page to permitted products page
    if (window.location.pathname === Paths.Home && !isAnalyticsPagePermitted) {
      navigate(`${Paths.Products}/all-products`);
    }
  }, []);
};
