import { baseAxiosInstance } from 'axios-config';
import {
  CreateProductConfigurationData,
  DuplicateProductConfigurationData,
  ManageProductConfigurationsData,
  ProductConfiguration,
  ProductConfigurationShort,
  UpdateProductConfigurationData,
  SkuData,
} from 'services/product-configurations.model';
import { RequestBody } from 'pages/product-flow/pages/product/controllers/edit-codes-modal.controller';
import { BarcodeValidationModel } from 'services/products.model';

export class ProductConfigurationService {
  public static async getConfigurationInfo(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductConfiguration>(`/product-configuration/info/${productId}`);

    return data;
  }

  public static async getAllByProductId(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductConfiguration[]>(`/product-configuration/all-by-product/${productId}`);

    return data;
  }

  public static async getAll(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductConfigurationShort[]>(
      `/product-configuration/all?product_id=${productId}`,
    );

    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<ProductConfiguration[]>(`/product-configuration/${id}`);

    return data;
  }

  public static async create(body: CreateProductConfigurationData) {
    const { data } = await baseAxiosInstance.post<ProductConfiguration>(`/product-configuration/create`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete(`/product-configuration/${id}`);

    return data;
  }

  public static async validate(sku: string, configurationId: string) {
    const { data } = await baseAxiosInstance.get<BarcodeValidationModel>(
      `/product-configuration/validate?sku=${sku}&id=${configurationId}`,
    );
    return data;
  }

  public static async update(id: string, body: UpdateProductConfigurationData) {
    const { data } = await baseAxiosInstance.put<ProductConfiguration>(`/product-configuration/${id}`, body);

    return data;
  }

  public static async duplicate(body: DuplicateProductConfigurationData) {
    const { data } = await baseAxiosInstance.post<ProductConfiguration>(`/product-configuration/duplicate`, body);

    return data;
  }

  public static async manage(body: ManageProductConfigurationsData) {
    const { data } = await baseAxiosInstance.post<ProductConfiguration>(`/product-configuration/manage-configuration`, body);

    return data;
  }

  public static async getSkuByKeyword(keyword: string) {
    const { data } = await baseAxiosInstance.get<SkuData[]>(`/product-configuration/get-all-sku`, {
      params: {
        search: keyword,
      },
    });

    return data;
  }

  public static async updateCodes(id: string, body: RequestBody) {
    const { data } = await baseAxiosInstance.put(`/product-configuration/codes/${id}`, body);

    return data;
  }
}
