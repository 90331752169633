import { SomethingWentWrong } from 'components/errors/something-went-wrong';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppState } from 'redux/store';
import { getNonAuthorizedEnvHostname } from 'tenant-helpers';
import { LogoBlueIcon } from 'icons/logo-blue';
import { Paths } from 'routes/paths';
import PagePlug from 'pages/page-plug/page-plug';
import { Actions as AuthActions } from 'redux/auth.controller';
import { Actions as AuthSelectCompanyActions } from 'pages/auth-select-company/auth-select-company.controller';
import { UnionIcon } from 'icons/union';
import { useEffect } from 'react';
import { Tenant, TenantUserStatus } from 'services/tenant.model';
import { CompanyLogo } from 'components/company-logo/company-logo';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import s from './auth-select-company.module.scss';

export const CompanySelectionMiddlewareRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.SelectCompany} errorElement={<SomethingWentWrong />} element={<ConnectedSelectCompany />} />
      <Route
        path={Paths.CompanyAccessDenied}
        errorElement={<SomethingWentWrong />}
        element={
          <PagePlug
            onLogoClick={() => {
              const destination = `${window.location.protocol}//${getNonAuthorizedEnvHostname()}/sign-in`;
              window.location.replace(destination);
            }}
            withUrl
            title="Access is denied"
            subtitle="You don`t have permission to access this page. Please contact your HESH administrator."
          />
        }
      />
      <Route path="*" element={<Navigate to={Paths.SelectCompany} />} />
    </Routes>
  );
};

type StateProps = {
  items: Tenant[];
  isLoading: boolean;
};

type DispatchProps = {
  logout: () => {};
  loadUserCompanies: () => {};
  selectCompany: (tenantId: string) => {};
};

const renderSkeleton = () => (
  <div className={s.skeleton_container}>
    <Skeleton variant="rounded" height={64} />
    <Skeleton variant="rounded" height={64} />
    <Skeleton variant="rounded" height={64} />
  </div>
);

const SelectCompany = (props: StateProps & DispatchProps) => {
  const { isLoading, items, logout, loadUserCompanies, selectCompany } = props;

  const activeCompanies = items?.filter((item) => item.user_status === TenantUserStatus.Active) || [];

  useEffect(() => {
    loadUserCompanies();
  }, []);

  return (
    <div className={s.container}>
      <div className={s.background_panel}>
        <UnionIcon className={s.background_sign} />
        <div className={s.company_selector_wrapper}>
          <div className={s.company_selector}>
            <a className={s.hesh_logo} href="https://www.hesh.app/" target="_blank" rel="noreferrer">
              <LogoBlueIcon />
            </a>
            <div className={s.cta_text}>Select a company</div>
            <div className={s.companies_container}>
              {isLoading
                ? renderSkeleton()
                : activeCompanies.map((item) => (
                    <button className={s.company_item} type="button" key={item.id} onClick={() => selectCompany(item.id)}>
                      <div className={s.company_logo}>
                        <CompanyLogo imgSrc={item.logo} companyName={item.name} size="M" />
                      </div>
                      <div>{item.name}</div>
                    </button>
                  ))}
            </div>
            <button className={s.logout_button} type="button" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  items: state.authSelectCompany.items,
  isLoading: state.authSelectCompany.isLoading,
});

const mapDispatchToProps: DispatchProps = {
  logout: AuthActions.logout,
  loadUserCompanies: AuthSelectCompanyActions.loadUserCompanies,
  selectCompany: AuthSelectCompanyActions.selectCompany,
};

const ConnectedSelectCompany = connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
