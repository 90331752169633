import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { ProductionWorkflow } from 'services/production-workflow.model';
import { ProductForSelect, ProductVariantForSelect, PublishedProductWithName } from 'services/products.model';
import { ProductConfigurationForSelect } from 'services/product-configurations.model';
import { ExternalOrderNumberForSelect, OrderKeyForSelect } from 'services/orders.model';
import { IdName } from 'types/common-types';
import { StateController } from 'state-controller';
import { ProductsService } from 'services/products.service';
import { Products2Icon } from 'icons/products-2';
import { OrdersService } from 'services/orders.service';
import { ClientService } from 'services/client.service';
import { GetStateFunction } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductVariantService } from 'services/product-variant.service';
import { versionName } from 'utils/version-name';
import { OrderProductionItemsService } from 'services/order-production-items.service';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductsLaunchModalActions } from 'pages/production/controllers/products-launch-modal-controller/products-launch-modal.controller';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';

export type ProductionNewModalState = {
  count: number;
  isOpen: boolean;
  isOrderCreating: boolean;
  isOrderCreatingBeforeLaunch: boolean;
  product: {
    isLoading: boolean;
    value: ProductForSelect;
    options: ProductForSelect[];
  };
  version: {
    isLoading: boolean;
    value: PublishedProductWithName;
    options: PublishedProductWithName[];
  };
  configuration: {
    isLoading: boolean;
    value: ProductConfigurationForSelect;
    options: ProductConfigurationForSelect[];
  };
  variant: {
    isLoading: boolean;
    value: ProductVariantForSelect;
    options: ProductVariantForSelect[];
  };
  orderKey: {
    isLoading: boolean;
    value: OrderKeyForSelect;
    options: OrderKeyForSelect[];
  };
  externalOrderNumber: {
    isLoading: boolean;
    value: ExternalOrderNumberForSelect;
    options: ExternalOrderNumberForSelect[];
  };
  client: {
    isLoading: boolean;
    value: IdName;
    options: IdName[];
  };
};

const defaultState: ProductionNewModalState = {
  count: 1,
  isOpen: false,
  isOrderCreating: false,
  isOrderCreatingBeforeLaunch: false,
  product: {
    isLoading: false,
    value: null,
    options: [],
  },
  version: {
    isLoading: false,
    value: null,
    options: [],
  },
  configuration: {
    isLoading: false,
    value: null,
    options: [],
  },
  variant: {
    isLoading: false,
    value: null,
    options: [],
  },
  orderKey: {
    isLoading: false,
    value: null,
    options: [],
  },
  externalOrderNumber: {
    isLoading: false,
    value: null,
    options: [],
  },
  client: {
    isLoading: false,
    value: null,
    options: [],
  },
};

const stateController = new StateController<ProductionNewModalState>('PRODUCTION_NEW_MODAL', defaultState);

export class ProductionNewModalActions {
  public static openModal() {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
        })),
      );
      dispatch(ProductionNewModalActions.initProductsWithVersions());
      dispatch(ProductionNewModalActions.initOrders());
      dispatch(ProductionNewModalActions.initClients());
    };
  }

  public static initProductsWithVersions() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
        const productsWithVersions = await ProductsService.getProductWithVersion();
        const options: ProductForSelect[] = productsWithVersions.map((product) => ({
          id: product.id,
          name: product.name,
          breadcrumbs: product.category_path,
          is_active: product.is_active,
          published_products: product.published_products,
          icon: <Products2Icon />,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              options,
              isLoading: false,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static initOrders() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: true,
            },
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: true,
            },
          })),
        );
        const orders = await OrdersService.getAllOrders();
        const orderKeys = orders.map((order) => ({ id: order.id, name: order.order_key, client_id: order.client_id }));
        const externalOrderNumbers = orders
          .map((order) => ({
            id: order.id,
            name: order.external_order_number,
            client_id: order.client_id,
          }))
          .filter((item) => !!item.name);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              options: orderKeys,
            },
            externalOrderNumber: {
              ...prev.orderKey,
              options: externalOrderNumbers,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            orderKey: {
              ...prev.orderKey,
              isLoading: false,
            },
            externalOrderNumber: {
              ...prev.externalOrderNumber,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static initClients() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: true,
            },
          })),
        );
        const clients = await ClientService.getAllClients('', 0, 9000);
        const options = clients.data.map((order) => ({ id: order.id, name: order.name }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              options,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            client: {
              ...prev.client,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static initProductConfigurations() {
    return async (dispatch, getState: GetStateFunction) => {
      const productId = getState().production.productionNewModal.version.value.id;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: true,
            },
          })),
        );
        const productConfigurations = await ProductConfigurationService.getAll(productId);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              value: productConfigurations?.sort((config1, config2) => config1.order - config2.order)[0],
              options: productConfigurations,
              isLoading: false,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static initProductVariants() {
    return async (dispatch, getState: GetStateFunction) => {
      const configurationId = getState().production.productionNewModal.configuration.value.id;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: true,
            },
          })),
        );
        const productVariants = await ProductVariantService.getAll(configurationId);
        const options = productVariants
          .filter((i) => i.is_active === true)
          .map((variant) => ({
            id: variant.id,
            name: `${variant.name} - ${variant.sku} - ${variant.barcode}`,
            is_active: variant.is_active,
          }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              value: null,
              options,
              isLoading: false,
            },
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static onValueProductChange(value: ProductionNewModalState['product']['value']) {
    return (dispatch) => {
      const productVersions = value.published_products.map((productVersion) => ({
        ...productVersion,
        name: versionName(productVersion.version),
      }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            value,
          },
          version: {
            ...prev.version,
            value: null,
            options: productVersions,
          },
          configuration: {
            ...prev.version,
            value: null,
            options: [],
          },
          variant: {
            ...prev.version,
            value: null,
            options: [],
          },
        })),
      );
      dispatch(ProductionNewModalActions.onValueVersionChange(productVersions[0]));
    };
  }

  public static onValueVersionChange(value: ProductionNewModalState['version']['value']) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          version: {
            ...prev.version,
            value,
          },
          configuration: {
            ...prev.version,
            value: null,
            options: [],
          },
          variant: {
            ...prev.version,
            value: null,
            options: [],
          },
        })),
      );
      await dispatch(ProductionNewModalActions.initProductConfigurations());
      dispatch(ProductionNewModalActions.initProductVariants());
    };
  }

  public static onValueConfigurationChange(value: ProductionNewModalState['configuration']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            value,
          },
          variant: {
            ...prev.version,
            value: null,
          },
        })),
      );
      dispatch(ProductionNewModalActions.initProductVariants());
    };
  }

  public static onValueVariantChange(value: ProductionNewModalState['variant']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          variant: {
            ...prev.variant,
            value,
          },
        })),
      );
    };
  }

  public static onValueOrderKeyChange(value: ProductionNewModalState['orderKey']['value']) {
    return (dispatch, getState: GetStateFunction) => {
      const { externalOrderNumber, client } = getState().production.productionNewModal;
      const chosenExternalOrderNumber = externalOrderNumber.options.find((item) => item.id === value.id);
      const chosenClient = client.options.find((item) => item.id === value.client_id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey: {
            ...prev.orderKey,
            value,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: chosenExternalOrderNumber || null,
          },
          client: {
            ...prev.client,
            value: chosenClient || null,
          },
        })),
      );
    };
  }

  public static onValueExternalOrderNumberChange(value: ProductionNewModalState['externalOrderNumber']['value']) {
    return (dispatch, getState: GetStateFunction) => {
      const { orderKey, client } = getState().production.productionNewModal;
      const chosenOrderKey = orderKey.options.find((item) => item.id === value.id);
      const chosenClient = client.options.find((item) => item.id === value.client_id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value,
          },
          orderKey: {
            ...prev.orderKey,
            value: chosenOrderKey || null,
          },
          client: {
            ...prev.client,
            value: chosenClient || null,
          },
        })),
      );
    };
  }

  public static onValueClientChange(value: ProductionNewModalState['client']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          client: {
            ...prev.client,
            value,
          },
          orderKey: {
            ...prev.orderKey,
            value: null,
          },
          externalOrderNumber: {
            ...prev.externalOrderNumber,
            value: null,
          },
        })),
      );
    };
  }

  public static onCountChange(value: number) {
    return (dispatch) => {
      dispatch(stateController.setState({ count: value }));
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static confirm(loadingKey: 'isOrderCreating' | 'isOrderCreatingBeforeLaunch') {
    return async (dispatch, getState: GetStateFunction) => {
      const {
        count,
        client,
        orderKey,
        externalOrderNumber,
        variant: { value: variantValue },
      } = getState().production.productionNewModal;

      try {
        if (loadingKey === 'isOrderCreating') {
          dispatch(stateController.setState({ isOrderCreating: true }));
        }

        if (loadingKey === 'isOrderCreatingBeforeLaunch') {
          dispatch(stateController.setState({ isOrderCreatingBeforeLaunch: true }));
        }

        let order_id = orderKey.value?.id || externalOrderNumber.value?.id;

        if (order_id === 'new' || !order_id) {
          const newOrder = await OrdersService.createOrder({
            ...(externalOrderNumber.value?.name ? { external_order_number: externalOrderNumber.value?.name } : {}),
            ...(client.value && client.value.name !== ''
              ? {
                  client: {
                    ...(client.value.id === 'new'
                      ? { name: client.value.name }
                      : { id: client.value.id, name: client.value.name }),
                  },
                }
              : {}),
          });
          order_id = newOrder.id;
        }

        const productionItems = await OrderProductionItemsService.create({
          order_id,
          quantity: count,
          product_variant_id: variantValue.id,
        });
        const productionWorkflows = await ProductionWorkflowService.create({
          order_id,
          product_variant_id: variantValue.id,
          order_production_item_ids: productionItems.map((item) => item.id),
        });

        await dispatch(ProductionFiltersActions.getProductsByFilter({ showFetchEffect: false }));
        dispatch(stateController.setState(defaultState));

        return {
          productVariantId: variantValue.id,
          productionWorkflows,
        };
      } finally {
        if (loadingKey === 'isOrderCreating') {
          dispatch(stateController.setState({ isOrderCreating: false }));
        }

        if (loadingKey === 'isOrderCreatingBeforeLaunch') {
          dispatch(stateController.setState({ isOrderCreatingBeforeLaunch: false }));
        }
      }
    };
  }

  public static confirmAndLaunch() {
    return async (dispatch) => {
      const { productionWorkflows, productVariantId }: { productionWorkflows: ProductionWorkflow[]; productVariantId: string } =
        await dispatch(ProductionNewModalActions.confirm('isOrderCreatingBeforeLaunch'));

      if (productionWorkflows.length === 1) {
        dispatch(
          ProductsLaunchModalActions.openModal({
            productVariantId,
            openedFrom: LocationTheProductionStatusIsChangingFrom.NewProductionModal,
            productionIdToLaunch: productionWorkflows[0].id,
          }),
        );
        return;
      }

      dispatch(
        ProductsLaunchModalActions.openMultiLaunchModal(
          productionWorkflows,
          LocationTheProductionStatusIsChangingFrom.NewProductionModal,
        ),
      );
    };
  }
}

export const reducer = stateController.getReducer();
