import { Filters, Pagination, User } from 'services/users-filter.model';
import { StateController } from 'state-controller';
import { UserFilterService } from 'services/users-filter.service';
import { AppState } from 'redux/store';
import { debounce } from 'utils/debounce';
import { IdName } from 'types/common-types';
import { UserService } from 'services/user.service';
import { toast } from 'react-toastify';
import { getSkipAndTake } from 'utils/get-skip-and-take';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { MODALS } from 'modules/root-modals/modals';
import { ActiveTickIcon } from 'icons/active-tick';

export enum UsersSortOptionsEnum {
  Name = 'user_name',
  Status = 'status',
  Date = 'created_date',
}

export type UsersState = {
  users: User[];
  isEmpty: boolean;
  isDataReload: boolean;
  isInitLoading: boolean;
  pagination: Pagination;
  filters: Filters;
  statusActiveConfirmModalOpen: boolean;
};

const defaultState: UsersState = {
  users: [],
  isEmpty: false,
  isInitLoading: false,
  isDataReload: false,
  pagination: {
    total: 0,
    perPage: 25,
    lastPage: 0,
    currentPage: 1,
  },
  filters: {
    search: '',
    sort_by: {
      id: 'user_name',
      name: 'User name',
    },
    order: 'asc',
    active_filter: 'all',
    vacation_filter: 'all',
    perPage: 25,
    active_filter_options: [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'inactive',
        name: 'Inactive',
      },
      {
        id: 'pending',
        name: 'Pending',
      },
    ],
    vacation_filter_options: [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'at_vacation',
        name: 'At vacation',
      },
      {
        id: 'not_at_vacation',
        name: 'Not at vacation',
      },
    ],
  },
  statusActiveConfirmModalOpen: false,
};

const stateController = new StateController<UsersState>('USERS_STATE', defaultState);

class Actions {
  public static init() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isInitLoading: true }));
      const { filters } = getState().users;
      const { perPage } = getState().users.pagination;
      const { data, meta } = await UserFilterService.getUsersWithFilters(filters, 0, perPage);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          users: data,
          isEmpty: meta.total === 0,
          pagination: {
            total: meta.total,
            currentPage: meta.currentPage,
            perPage: meta.perPage,
            lastPage: meta.lastPage,
          },
          isInitLoading: false,
        })),
      );
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static getUsersByFilters() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isDataReload: true }));
      const { filters, pagination } = getState().users;
      const { skip, take } = getSkipAndTake(pagination.perPage, pagination.currentPage);
      const { data, meta } = await UserFilterService.getUsersWithFilters(filters, skip, take);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          users: data,
          pagination: {
            total: meta.total,
            currentPage: meta.currentPage,
            perPage: meta.perPage,
            lastPage: meta.lastPage,
          },
          isDataReload: false,
        })),
      );
    };
  }

  public static onFilterChange(key: string, value: string | IdName) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev: UsersState) => ({
          ...prev,
          filters: {
            ...prev.filters,
            [key]: value,
          },
          pagination: {
            ...prev.pagination,
            currentPage: 1,
          },
        })),
      );

      debounce(async () => {
        await dispatch(Actions.getUsersByFilters());
      }, 1000);
    };
  }

  public static changePagination(key: string, value: number) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev: UsersState) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            [key]: value,
          },
        })),
      );

      debounce(async () => {
        await dispatch(Actions.getUsersByFilters());
      }, 1000);
    };
  }

  public static clearFilters() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev: UsersState) => ({
          ...prev,
          filters: {
            ...prev.filters,
            search: '',
            sort_by: 'user_name',
            order: 'asc',
            active_filter: 'all',
            vacation_filter: 'all',
          },
        })),
      );

      dispatch(Actions.getUsersByFilters());
    };
  }

  public static updateStatus(id: string, status: string, userData: FormData) {
    return async (dispatch) => {
      try {
        await UserService.updateUser(id, userData);
      } catch (error) {
        toast(error.message);
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            users: prev.users.map((user) => {
              if (user.id === id) {
                return {
                  ...user,
                  status,
                };
              }
              return user;
            }),
          })),
        );
      }
    };
  }

  public static openStatusActiveConfirmModalOpen(id: string, status: string, userData: FormData) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            text: null,
            title: 'Activate?',
            withCloseButton: false,
            actionText: 'Activate',
            backgroundColor: '#BCF4DE',
            action: () => dispatch(Actions.updateStatus(id, status, userData)),
            icon: <ActiveTickIcon />,
          },
        }),
      );
    };
  }
}

class Selectors {}

const reducer = stateController.getReducer();

export { reducer, Actions, Selectors, stateController as Controller };
