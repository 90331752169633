import { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { Selectors as AuthSelectors } from 'redux/auth.controller';
import { getDesiredTenantByUrl } from 'tenant-helpers';
import Header from './components/header/header';

import s from './authorize-layout.module.scss';
import { SidebarMenu } from './components/sidebar-menu/sidebar-menu';

export const AuthorizeLayoutWrapper = ({ children }: PropsWithChildren<{}>) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isDisabledTransition, setIsDisabledTransition] = useState(true);
  const user_permissions = useSelector((state: AppState) => state.auth?.user?.user_permissions);
  const isUserActive = useSelector((state: AppState) => AuthSelectors.isUserActiveInTenant(state));
  const userTenants = useSelector((state: AppState) => state.auth.userTenants);
  const desiredTenant = getDesiredTenantByUrl(window.location.href, userTenants);
  const isShowSidebar = desiredTenant && (!isUserActive || user_permissions);

  useEffect(() => {
    setTimeout(() => {
      setIsDisabledTransition(false);
    }, 700);
  }, []);

  return (
    <div className={s.container}>
      <aside className={`${isDisabledTransition ? s.no_transition : ''}`}>
        {isShowSidebar && <SidebarMenu isMobileMenuOpened={isMobileMenuOpened} setIsMobileMenuOpened={setIsMobileMenuOpened} />}
      </aside>
      <div className={s.content_wrapper}>
        <Header isMobileMenuOpened={isMobileMenuOpened} setIsMobileMenuOpened={setIsMobileMenuOpened} />
        <div className={s.content} data-is-mobile-menu-opened={isMobileMenuOpened}>
          {children}
        </div>
      </div>
    </div>
  );
};
