import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { IdName } from 'types/common-types';
import { StateController } from 'state-controller';
import { ProductVariantService } from 'services/product-variant.service';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';

export type EditVariantModalArgs = {
  page?: Page;
  productName: string;
  productionId: string;
  productionKey: string;
  productVariant: IdName;
  configurationId: string;
};

export type EditVariantModalState = {
  variants: any[];
  isOpen: boolean;
  page: Page | null;
  isLoading: boolean;
  isFetching: boolean;
  productName: string;
  initialData: IdName;
  productionId: string;
  productionKey: string;
  isValueChanged: boolean;
  selectedVariant: IdName;
  isApplyToComponents: boolean;
};

const defaultState: EditVariantModalState = {
  page: null,
  variants: [],
  isOpen: false,
  productName: '',
  productionId: '',
  isLoading: false,
  isFetching: false,
  productionKey: '',
  isValueChanged: false,
  isApplyToComponents: false,
  initialData: {
    id: '',
    name: '',
  },
  selectedVariant: {
    id: '',
    name: '',
  },
};

const stateController = new StateController<EditVariantModalState>('EDIT_VARIANT_MODAL', defaultState);

export class EditVariantActions {
  public static openModal({
    productionId,
    productName,
    productVariant,
    productionKey,
    configurationId,
    page,
  }: EditVariantModalArgs) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      try {
        dispatch(stateController.setState({ isFetching: true }));
        const data = await ProductVariantService.getAll(configurationId);
        const mappedVariants = data.map((i) => ({
          id: i.id,
          name: i.name,
        }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            page,
            productName,
            productionId,
            productionKey,
            isOpen: true,
            variants: mappedVariants,
            initialData: {
              id: productVariant?.id || '',
              name: productVariant?.name || '',
            },
            selectedVariant: {
              id: productVariant?.id || '',
              name: productVariant?.name || '',
            },
          })),
        );
      } finally {
        dispatch(stateController.setState({ isFetching: false }));
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { productionId, isApplyToComponents, selectedVariant, page } = getState().production.editVariantModal;
        const body = {
          product_variant_id: selectedVariant.id,
          replace_variant_in_components: isApplyToComponents,
        };
        await ProductionWorkflowService.updateProductionWorkflowDetails(productionId, body);

        if (page === Page.InfoDropdownWorkflow) {
          await loadData(productionId);
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }
        if (page === Page.InfoDropdownProduction) {
          await loadData(productionId);
          const customGroupBy = getState().production.filters.groupBy;
          const { currentPage } = getState().production.filters.pagination;
          await dispatch(ProductionFiltersActions.getProductsByFilter({ currentPage, customGroupBy, showFetchEffect: false }));
        }
        if (page === Page.Production) {
          const { currentPage } = getState().production.filters.pagination;
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(ProductionFiltersActions.getProductsByFilter({ currentPage, customGroupBy, showFetchEffect: false }));
        }
        if (page === Page.Workflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        dispatch(EditVariantActions.closeModal());
        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static switchShowWarning() {
    return async (dispatch, getState: () => AppState) => {
      const { initialData, selectedVariant } = getState().production.editVariantModal;
      if (initialData.id !== selectedVariant.id) {
        dispatch(stateController.setState({ isValueChanged: true }));
      } else {
        dispatch(stateController.setState({ isValueChanged: false }));
      }
    };
  }

  public static onVariantChange(value: IdName) {
    return async (dispatch) => {
      const selectedVariant = {
        id: value.id,
        name: value.name,
      };
      dispatch(stateController.setState({ selectedVariant }));
      dispatch(EditVariantActions.switchShowWarning());
    };
  }

  public static onApplyToComponents(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isApplyToComponents: value }));
    };
  }
}

export const reducer = stateController.getReducer();
