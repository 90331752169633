import { FC } from 'react';
import { LogoBlueIcon } from 'icons/logo-blue';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'components/button/button';

import s from './page-plug.module.scss';

type OwnProps = {
  url?: string;
  title: string;
  withUrl?: boolean;
  subtitle?: string;
  onLogoClick?: () => void;
  onBackClick?: VoidFunction;
};

const PagePlug: FC<OwnProps> = ({ url, title, withUrl, subtitle, onLogoClick, onBackClick }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const fromUrl = url ?? searchParams.get('url');

  const handleOnClick = () => {
    if (onLogoClick) {
      onLogoClick();
    } else {
      navigate('/');
    }
  };

  return (
    <div className={s.container}>
      <div className={s.panel} data-withurl={withUrl}>
        <LogoBlueIcon style={{ cursor: 'pointer' }} onClick={handleOnClick} />
        {withUrl && fromUrl && (
          <a href={fromUrl} className={s.url}>
            {fromUrl}
          </a>
        )}
        <div className={s.title}>{title}</div>
        <div className={s.subtitle}>{subtitle}</div>
        {state?.showBackButton && (
          <Button color="primary" variant="contained" size="XL" className={s.back_button} onClick={onBackClick}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
};

export default PagePlug;
