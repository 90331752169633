import { baseAxiosInstance } from 'axios-config';
import { IdName } from 'types/common-types';
import {
  CreateProductCategoryData,
  IsProductCategoryDeleteResponse,
  ProductCategory,
  ProductCategoryWithChildren,
  UpdateProductCategory,
  UpdateProductCategoryData,
} from './product-categories.model';

export class ProductCategoriesService {
  public static async getProductCategories() {
    const { data } = await baseAxiosInstance.get<ProductCategory[]>(`/product-categories/all`);

    return data;
  }

  public static async getProductCategoriesWithChildren(categoryId?: string) {
    const { data } = await baseAxiosInstance.get<ProductCategoryWithChildren>(
      `/product-categories/with-children/${categoryId ? `${categoryId}` : ','}`,
    );
    return data;
  }

  public static async changeIsActive(id: string, categoryData: UpdateProductCategory) {
    const { data } = await baseAxiosInstance.put<ProductCategory>(`/product-categories/activate-deactivate/${id}`, categoryData);
    return data;
  }

  public static async canDeleteProductCategory(id: string) {
    const { data } = await baseAxiosInstance.get<IsProductCategoryDeleteResponse>(`/product-categories/delete/${id}`);

    return data;
  }

  public static async createProductCategory(body: CreateProductCategoryData) {
    const { data } = await baseAxiosInstance.post<ProductCategory>(`/product-categories/create`, body);

    return data;
  }

  public static async updateProductCategory(id: string, body: UpdateProductCategoryData) {
    const { data } = await baseAxiosInstance.put<ProductCategory>(`/product-categories/${id}`, body);

    return data;
  }

  public static async updateParentCategory(categoryIds: string[], categoryId: string) {
    const { data } = await baseAxiosInstance.put(`/product-categories/update-parent-category`, {
      categoryIds,
      parent_id: categoryId,
    });

    return data;
  }

  public static async deleteProductCategory(id: string) {
    const { data } = await baseAxiosInstance.delete<ProductCategory>(`/product-categories/${id}`);

    return data;
  }

  public static async getCategoriesByKeyword(keyword: string) {
    const { data } = await baseAxiosInstance.get<IdName[]>(`/product-categories/find-all`, {
      params: {
        search: keyword,
      },
    });

    return data;
  }
}
