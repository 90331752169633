import { FC } from 'react';
import Label from 'components/label/label';
import { Input } from 'components/ui-new/inputs/input';
import s from './input-modal-item.module.scss';

type Props = {
  title: string;
  placeholder: string;
  value: string;
  errorMessage: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const InputModalItem: FC<Props> = (props) => {
  const { title, placeholder, value, errorMessage, onChange, disabled = false } = props;
  return (
    <div className={s.input_container}>
      <Label>{title}</Label>
      <Input
        size="small"
        error={!!errorMessage}
        placeholder={placeholder}
        fullWidth
        value={value ?? ''}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default InputModalItem;
